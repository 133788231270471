import storeFactory from '@state/template'

const apiResourceName = 'virtualagents/mails'
const modelName = 'virtualagentsmails'

const model = {
  id: null,
  template: null,
  subject: null,
  content_subtitle: '',
  content_text: '',
  created_at: null,
  updated_at: null,
}

const filters = {
  template: null,
  subject: null,
  content_subtitle: null,
  attachments: [],
}

const mutations = {
  SET_CURRENT(state, values) {
    state.current = { ...state.current, ...values }

    if (state.current.attachments && state.current.attachments.length) {
      state.current.attachments = state.current.attachments.map(
        (p) => p.id
      )
    }
  },
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  mutations,
  filters
})
