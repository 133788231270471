/* eslint-disable camelcase */
import {
  extend,
  localize,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import {
  email,
  integer,
  max,
  min,
  min_value,
  numeric,
  regex,
  required,
  required_if,
  length,
  size,
} from 'vee-validate/dist/rules'
import Vue from 'vue'
import moment from 'moment'
import { isString, flow, isNaN, negate } from 'lodash'
import { parsePhoneNumber } from 'libphonenumber-js'

localize('it', it)

setInteractionMode('eager')

// Add the required rule
extend('required', required)
extend('required_if', required_if)
extend('email', email)
extend('integer', {
  validate: integer.validate,
  message: 'Il valore deve essere un numero intero',
})
// Min tests string or array length
extend('min', min)
// Min value is for numbers
extend('min_value', min_value)
extend('max', max)
extend('length', length)
extend('numeric', numeric)
extend('regex', regex)
extend('size', size)
extend('url', {
  validate(value) {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      )
    }

    return false
  },
  message: 'Il valore non è un URL valido',
})
extend('https', {
  validate(value) {
    if (value) {
      return /^https:/.test(value)
    }

    return false
  },
  message: 'Il valore non è un URL sicuro',
})

extend('confirm', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'il campo di conferma con corrisponde',
})

extend('customer_address', {
  validate: (value) => {
    try {
      const isEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)

      if (isEmail) return true

      const phoneNumber = parsePhoneNumber(value, 'IT')
      return phoneNumber.isValid()
    } catch (err) {
      return false
    }
  },
  message: 'Inserisci una mail o un numero di cellulare valido.',
})

extend('pwd_len', {
  validate: (value) => /^.{8,}$/.test(value),
  message: 'La password deve contenere almeno 8 caratteri',
})

extend('pwd_schars', {
  validate: (value) => {
    const matches = value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g)
    return matches && matches.length >= 2
  },
  message: 'la password deve contenere almeno 2 caratteri speciali',
})

extend('pwd_chars', {
  validate: (value) => {
    const matches = value.match(/[A-z]/g)
    return matches && matches.length >= 2
  },
  message: 'La password deve contenere almeno 2 lettere',
})

extend('pwd_caps', {
  validate: (value) => {
    const matches = value.match(/[A-Z]/g)
    return matches && matches.length >= 1
  },
  message: 'La password deve contenere almeno 1 carattere maiuscolo',
})

extend('pwd_num', {
  validate: (value) => {
    const matches = value.match(/[0-9]/g)
    return matches && matches.length >= 2
  },
  message: 'La password deve contenere almeno 2 numeri',
})

extend('date_after', {
  params: ['target'],
  validate(value, { target }) {
    return moment(value).isAfter(target)
  },
  message: 'Data non valida',
})

extend('date_before', {
  params: ['target'],
  validate(value, { target }) {
    return moment(value).isBefore(target)
  },
  message: 'Data non valida',
})

extend('in_values', {
  params: ['values', 'field'],
  validate(value, { values, field }) {
    return values.some((v) =>
      isString(v) ? v === value : v[field || 'value'] === value
    )
  },
  message: 'Valore non disponibile',
})

extend('phone', {
  validate: (value) => {
    try {
      const phoneNumber = parsePhoneNumber(value, 'IT')
      return phoneNumber && phoneNumber.isValid()
    } catch (e) {
      return false
    }
  },
  message: 'Il telefono non è valido',
})

extend('tel', {
  validate: (value) => {
    return value.match(/(^0\d{7,9})$/)
  },
  message: 'Il telefono deve essere valido / inizia con 0',
})

extend('mobile', {
  validate: (value) => {
    return value.match(/([+]?\d{1,2}[\s-]?)?(\d{3}[\s-]?){2}\d{4}$/)
  },
  message: 'Il cellulare deve essere valido',
})
extend('valid_json', {
  validate: (value) => {
    let isValid
    try {
      JSON.parse(value)
      isValid = true
    } catch (error) {
      isValid = false
    }
    return isValid
  },
  message: 'Il JSON inserito non è valido',
})
extend('number', {
  validate: flow([parseFloat, negate(isNaN)]),
  message: 'Il valore inserito non è un numero valido.',
})

// Register it globally
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
