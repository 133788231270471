<template>
  <div>
    <div v-if="webenv === 'cwm'" class="align-center">
      <v-row>
        <v-col class="align-center text-center">
          <v-img
            src="@assets/logo/vadofone_logotype_slim.svg"
            width="64"
            height="64"
          />
        </v-col>
        <v-col>
          <h1 class="text-h3"><strong>cloud</strong>workplace</h1>
          <h1 class="text-h5 font-weight-thin secondary-text">
            m a n a g e m e n t
          </h1>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="webenv === 'eni tuttosmart'" class="align-center">
      <v-row>
        <v-col class="align-center text-center">
          <v-img
            src="@assets/logo/tim_logotype.svg"
            contain
            width="200"
            height="100"
            class="mx-auto"
          />
          <h1 class="text-h3">
            <strong>ENI</strong>
            <span class="font-weight-thin secondary-text grey--text">
              Tuttosmart
            </span>
          </h1>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="webenv === 'knox services'" class="align-center">
      <v-row>
        <v-col class="align-center text-center">
          <h1 class="text-h3">
            <strong>knox</strong>
            <span class="font-weight-thin secondary-text grey--text">
              services
            </span>
          </h1>
          <v-img
            src="@assets/logo/windtre_logotype.svg"
            contain
            width="128"
            height="90"
            class="mx-auto mt-4"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <h1 class="text-h5 font-weight-thin secondary-text">
        D i g i t a l
      </h1>
      <h1 class="text-h3"><strong>ONE</strong>SOLUTION</h1>
    </div>
  </div>
</template>

<script>
import utils from '@mixins/utils.js'
export default {
  name: 'FrontpageLogo',
  mixins: [utils],
}
</script>
