import storeFactory from '@state/template'

const apiResourceName = 'uploads/error'
const modelName = 'errore'
const model = {
  id: null,
  id_import: null,
}

const filters = {
  id: null,
  id_import: null,
}

const dropdownScopes = ['dropdown']
const sortDropdownBy = []
const sortDropdownDesc = []

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
