import storeFactory from '@state/template'
import { curry, get, merge, snakeCase, toUpper, upperFirst } from 'lodash'

const apiResourceName = 'virtualagents/presets'
const modelName = 'Assistente virtuale'

const model = {
  approach: null,
  arrears: null,
  attitude: null,
  created_at: null,
  deleted_at: null,
  first_time: null,
  id: null,
  loyalty: [],
  name: null,
  reason: null,
  role: null,
  sentiment: null,
  service: null,
  skill_type: [],
  spending_range: null,
  df_agent_id: null,
  fidelity: null,
  growth: null,
  updated_at: null,
}
const filters = {
  approach: null,
  arrears: false,
  attitude: null,
  created_at: null,
  deleted_at: null,
  first_time: null,
  id: null,
  loyalty: null,
  name: null,
  reason: null,
  role: null,
  sentiment: null,
  service: null,
  skill_type: null,
  spending_range: null,
  df_agent_id: null,
  fidelity: null,
  growth: null,
  updated_at: null,
}

const state = {
  approaches: [],
  arrears: [],
  attitudes: [],
  firstTimeOptions: [],
  loyalties: [],
  roles: [],
  sentiments: [],
  skillTypes: [],
  spendingRanges: [],
  paramFields: [],
}

// item_name_in_state - default_value_of_mock
const indexer = [
  ['approaches', ['formal', 'youthful']],
  ['arrears', ['true', 'false']],
  ['attitudes', ['positive', 'helpful', 'competitive']],
  ['firstTimeOptions', ['yes', 'no']],
  ['loyalties', ['no', '0', '1+', '3+']],
  ['roles', ['assertive', 'severe', 'broker']],
  ['sentiments', ['positive', 'neutral', 'negative']],
  ['skillTypes', ['administrative', 'commercial', 'technological']],
  ['spendingRanges', ['low', 'medium', 'high']],
  ['paramFields', []],
]

const makeMutation = ([key]) => {
  return {
    [`SET_${toUpper(snakeCase(key))}`]: (state, value) => (state[key] = value),
    [`RESET_${toUpper(snakeCase(key))}`]: (state) => (state[key] = []),
  }
}

const makeAction = ([key, defaultValue]) => ({
  [`get${upperFirst(key)}`]: async ({ commit }) => {
    try {
      commit('SET_LOADING')
      const data = await fake(defaultValue)
      commit(`SET_${toUpper(snakeCase(key))}`, data)
    } catch (err) {
    } finally {
      commit('RESET_LOADING')
    }
  },
})

const fake = (data) =>
  new Promise((resolve) => setTimeout(() => resolve(data), 20))

const mutations = {
  ...indexer.map(makeMutation).reduce(merge),
}
const actions = {
  ...indexer.map(makeAction).reduce(merge),
}
const dropdownScopes = []
const sortDropdownBy = []
const sortDropdownDesc = [false]

const getFrom = curry(get, 2)

const statuses = {
  approaches: {
    formal: 'Formale',
    youthful: 'Giovanile / informale',
  },
  arrears: {
    false: 'Cliente regolare',
    true: 'Cliente moroso',
  },
  attitudes: {
    competitive: 'Competitivo',
    helpful: 'Collaborativo',
    positive: 'Migliorativo',
  },
  firstTimeOptions: {
    no: 'Richiesta ripetuta',
    yes: 'Prima volta',
  },
  loyalties: {
    '0' : 'Da meno di un anno',
    '1+': 'Da almeno un anno',
    '3+': 'Più di 3 anni',
    no: 'No',
  },
  roles: {
    assertive: 'Assertivo',
    severe: 'Severo',
    broker: 'Negoziatore',
  },
  sentiments: {
    negative: 'Irritato',
    neutral: 'Neutrale',
    positive: 'Bendisposto',
  },
  skillTypes: {
    administrative: 'Amministrativa',
    commercial: 'Commerciale',
    technological: 'Tecnica',
  },
  spendingRanges: {
    high: 'Alta',
    low: 'Bassa',
    medium: 'Media',
  },
}

const getters = {
  labels: (state) =>
    getFrom(statuses),
  approaches: (state, getters) => {
    const getLabel = getFrom(getters.labels('approaches'))
    return state.approaches.map((s) => ({
      text: getLabel(s),
      value: s,
    }))
  },
  arrears: (state, getters) => {
    const getLabel = getFrom(getters.labels('arrears'))
    return state.arrears.map((a) => ({
      text: getLabel(a),
      value: a,
    }))
  },
  attitudes: (state, getters) => {
    const getLabel = getFrom(getters.labels('attitudes'))
    return state.attitudes.map((a) => ({
      text: getLabel(a),
      value: a,
    }))
  },
  firstTimeOptions: (state, getters) => {
    const getLabel = getFrom(getters.labels('firstTimeOptions'))
    return state.firstTimeOptions.map((ft) => ({
      text: getLabel(ft),
      value: ft,
    }))
  },
  loyalties: (state, getters) => {
    const getLabel = getFrom(getters.labels('loyalties'))
    return state.loyalties.map((l) => ({
      text: getLabel(l),
      value: l,
    }))
  },
  roles: (state, getters) => {
    const getLabel = getFrom(getters.labels('roles'))
    return state.roles.map((role) => ({
      text: getLabel(role),
      value: role,
    }))
  },
  sentiments: (state, getters) => {
    const getLabel = getFrom(getters.labels('sentiments'))
    return state.sentiments.map((s) => ({
      text: getLabel(s),
      value: s,
    }))
  },
  skillTypes: (state, getters) => {
    const getLabel = getFrom(getters.labels('skillTypes'))
    return state.skillTypes.map((skill) => ({
      text: getLabel(skill),
      value: skill,
    }))
  },
  spendingRanges: (state, getters) => {
    const getLabel = getFrom(getters.labels('spendingRanges'))
    return state.spendingRanges.map((sr) => ({
      text: getLabel(sr),
      value: sr,
    }))
  },
  skillType : (state) => (key) => statuses.skillTypes[key],
}

export default storeFactory({
  actions,
  apiResourceName,
  filters,
  getters,
  model,
  modelName,
  mutations,
  state,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
