/* eslint-disable no-prototype-builtins */
import firebase from 'firebase/app'
import 'firebase/messaging'
import 'firebase/analytics'
import { netRequest } from '@api/client'

const firebaseConfig = {
  apiKey: 'AIzaSyAuEILnPnZst-Rf0HPb39jMhXmBzfUbFLU',
  authDomain: 'd1s-platform-evo.firebaseapp.com',
  projectId: 'd1s-platform-evo',
  storageBucket: 'd1s-platform-evo.appspot.com',
  messagingSenderId: '322965955261',
  appId: '1:322965955261:web:a1ef1865665fdfb04a0dd4',
  measurementId: 'G-Z31XHZB10F',
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

const SaveToken = async (token) => {
  await netRequest('PUT', '/auth/push_token', { token })
  window.localStorage.setItem('messagingToken', token)
}

const pushSupported =
  self && 'ServiceWorkerGlobalScope' in self
    ? 'PushManager' in self &&
      'Notification' in self &&
      ServiceWorkerRegistration.prototype.hasOwnProperty('showNotification') &&
      PushSubscription.prototype.hasOwnProperty('getKey')
    : navigator.cookieEnabled &&
      'serviceWorker' in navigator &&
      'PushManager' in window &&
      'Notification' in window &&
      'fetch' in window &&
      ServiceWorkerRegistration.prototype.hasOwnProperty('showNotification') &&
      PushSubscription.prototype.hasOwnProperty('getKey')

export const LogEvent = (...params) => {
  firebase.analytics().logEvent(...params)
}

const getMessaging = () => {
  try {
    if (!pushSupported) throw Error('Firebase Not supported')

    const messaging = firebase.messaging()

    return messaging
  } catch (err) {
    console.log('Unable to check Firebase')
  }
}

export const RegisterNotifications = async () => {
  try {
    await Notification.requestPermission()

    const token = await getMessaging().getToken()
    console.info('FB Token Acquired', token)

    const currentMessageToken = window.localStorage.getItem('messagingToken')
    if (currentMessageToken !== token) {
      await SaveToken(token)
    }
  } catch (err) {
    console.warn('[RegisterNotifications] Error', err)
    // throw err
  }
}

export const AttachNotificationHandler = (callback) => {
  getMessaging().onMessage(callback)
}

export const fbUtils = {
  getMessagingToken() {
    getMessaging()
      .getToken()
      .then(async (token) => {
        if (token) {
          const currentMessageToken = window.localStorage.getItem(
            'messagingToken'
          )
          // 'token will be updated', currentMessageToken !== token)
          if (currentMessageToken !== token) {
            await this.saveToken(token)
          }
        } else {
          // 'No Instance ID token available. Request permission to generate one.'
          return this.notificationsPermisionRequest()
        }
      })
  },
  notificationsPermisionRequest() {
    return getMessaging()
      .requestPermission()
      .then(() => {
        this.getMessagingToken()
      })
  },
  listenTokenRefresh() {
    const currentMessageToken = window.localStorage.getItem('messagingToken')

    if (currentMessageToken) {
      getMessaging().onTokenRefresh(function() {
        getMessaging()
          .getToken()
          .then(async function(token) {
            await this.saveToken(token)
          })
          .catch((err) => {
            console.log('unable to save', err)
          })
      })
    }
  },

  async saveToken(token) {
    try {
      await netRequest('PUT', '/auth/push_token', { token })

      window.localStorage.setItem('messagingToken', token)
    } catch (e) {
      console.log('unable to save token')
    }
  },

  onMessage(callback) {
    getMessaging().onMessage((payload) => {
      console.info('Push received. ', payload)
      callback(payload)
    })
  },
}
