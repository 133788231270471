import storeFactory from '@state/template'

const model = {
  subsid: null,
  skill_type: null,
  reqtype: null,
  status: null,
  customer_info: null,
  subscription_info: null,
  plan_code: null,
}

const modelName = 'Ordini Digital Store'
const apiResourceName = 'timopenrequests'

const filters = {
  company_name: null,
  first_name: null,
  last_name: null,
  cf: null,
  plan_id: null,
  reqtype: null,
  status: null,
}

const statuses = {
  active: {
    label: 'Attivo',
    color: 'success',
  },
  pending: {
    label: 'Pending',
    color: 'gray',
  },
  customer_created: {
    label: 'Cliente creato',
    color: 'gray',
  },
  provisioned: {
    label: 'Evaso',
    color: 'gray',
  },
  waiting_activation: {
    label: 'In attesa validazione',
    color: 'orange',
  },
  waiting_delivery: {
    label: 'In attesa di consegna',
    color: 'orange',
  },
  revoked: {
    label: 'Revocato',
    color: 'gray',
  },
  cancelled_ok: {
    label: 'Cancellato',
    color: 'blue',
  },
  cancelled_ko: {
    label: 'Cancellazione KO',
    color: 'gray',
  },
  error: {
    label: 'In errore',
    color: 'red',
  },
}

const getters = {
  statusLabel: (state) => (key) => statuses[key]?.label,
  statusColor: (state) => (key) => statuses[key]?.color,
  statuses: (state) => {
    return Object.keys(statuses).map((s) => ({
      text: statuses[s].label,
      value: s,
    }))
  },
  reqtypes: (state) => {
    return ['PROVISIONING', 'CHANGE', 'CANCELLATION']
  },
}

export default storeFactory({
  model,
  modelName,
  apiResourceName,
  filters,
  getters,
})
