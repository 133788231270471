import { netRequest } from '@api/client'
import { isArray, isString, isEmpty } from 'lodash'

export const state = {
  regions: [],
  provinces: [],
}

export const getters = {
  provincesByRegions: (state) => (regions) => {
    if (isString(regions))
      return state.provinces.filter((p) => regions === p.region)

    if (!regions || !isArray(regions) || isEmpty(regions))
      return state.provinces

    return state.provinces.filter((p) => regions.includes(p.region))
  },
}

export const mutations = {
  SET_REGIONI(state, regioni) {
    state.regions = regioni.sort()
  },
  SET_PROVINCE(state, province) {
    state.provinces = province
  },
}

export const actions = {
  async preload({ commit }) {
    try {
      const { regions } = await netRequest(
        'GET',
        '/utils'
      )

      const regioni = [...new Set(regions.map((i) => i.regione))]
      const province = regions.map((i) => ({
        text: i.provincia,
        value: i.codice,
        region: i.regione,
      }))

      commit('SET_REGIONI', regioni)
      commit('SET_PROVINCE', province)
    } catch (err) {
      console.log('errore preload', err)
    }
  },

}
