import store from '@state/store.js'
import { lazyLoadView } from '@router/routes.js'

export default [
  {
    path: '/manage',
    component: () => lazyLoadView(import('@views/main.vue')),
    name: 'base',
    children: [
      ...(process.env.NODE_ENV === 'development'
        ? [
            {
              path: '/manage/dev',
              name: 'dev',
              component: () => lazyLoadView(import('@views/dev.vue')),
              meta: {},
            },
          ]
        : []),

      {
        path: '/manage',
        name: 'home',
        component: () => lazyLoadView(import('@views/dashboard/dashboard.vue')),
        meta: {
          authRequired: true,
          section: 'dashboard',
          action: 'read',
        },
      },

      // virtual agent
      {
        path: '/manage/virtual_agents',
        name: 'virtualagents_dashboard',
        component: () =>
          lazyLoadView(import('@views/virtualagent/dashboard.vue')),
        meta: {
          authRequired: true,
          section: 'virtual_agents',
          action: 'search',
        },
      },
      {
        path: '/manage/virtual_agents/history',
        name: 'virtualagents_sessions_index',
        component: () => lazyLoadView(import('@views/virtualagent/search.vue')),
        meta: {
          authRequired: true,
          section: 'virtual_agents',
          action: 'search',
        },
      },
      // virtual agent
      // {
      //   path: '/manage/virtualagents/create',
      //   name: 'virtualagents_create_session',
      //   component: () => lazyLoadView(import('@views/virtualagent/index.vue')),
      //   meta: {
      //     authRequired: true,
      //     section: 'virtual_agents',
      //     action: 'create',
      //   },
      // },
      // virtual agent
      {
        path: '/manage/virtual_agents/create',
        name: 'virtualagents_create_quick',
        component: () => lazyLoadView(import('@views/virtualagent/create.vue')),
        meta: {
          authRequired: true,
          section: 'virtual_agents',
          action: 'create',
        },
      },
      {
        path: '/manage/virtual_agents/mail/search',
        name: 'virtualagents_search_mails',
        component: () =>
          lazyLoadView(import('@views/virtualagent/mails/search.vue')),
        meta: {
          authRequired: true,
          section: 'virtual_agents_mails',
          action: 'search',
        },
      },
      {
        path: '/manage/virtual_agents/attachment/search',
        name: 'virtualagents_search_attachments',
        component: () =>
          lazyLoadView(import('@views/virtualagent/attachments/search.vue')),
        meta: {
          authRequired: true,
          section: 'virtual_agents_attachments',
          action: 'search',
        },
      },

      {
        path: '/manage/virtual_agents/presets/search',
        name: 'virtualagents_search_presets',
        component: () =>
          lazyLoadView(import('@views/virtualagent/presets/search.vue')),
        meta: {
          authRequired: true,
          section: 'virtual_agents_presets',
          action: 'search',
        },
      },

      // customers
      {
        path: '/manage/customers',
        name: 'customers_search',
        component: () => lazyLoadView(import('@views/customers/search.vue')),
        meta: {
          authRequired: true,
          section: 'customers',
          action: 'search',
        },
      },
      {
        path: '/manage/customers/create',
        name: 'customers_create',
        component: () => lazyLoadView(import('@views/customers/create.vue')),
        meta: {
          authRequired: true,
          section: 'customers',
          action: 'create',
        },
      },
      {
        path: '/manage/customers/:id',
        name: 'customers_detail',
        component: () => lazyLoadView(import('@views/customers/details.vue')),
        meta: {
          authRequired: true,
          section: 'customers',
          action: 'read',
        },
      },
      {
        path: '/manage/users',
        name: 'customers_users_search',
        component: () => lazyLoadView(import('@views/users/search.vue')),
        meta: {
          authRequired: true,
          section: 'customers.users',
          action: 'search',
        },
      },

      // Plans subscriptions
      {
        path: '/manage/plans_subscriptions',
        name: 'plans_subscriptions',
        component: () =>
          lazyLoadView(import('@views/plans_subscriptions/search.vue')),
        meta: {
          authRequired: true,
          section: 'plans_subscriptions',
          action: 'search',
        },
      },

      // gestione richeiste Tim Open
      {
        path: '/manage/plans_subscriptions/tim',
        name: 'timopen_request',
        component: () =>
          import('@views/plans_subscriptions/timeopenrequests/search.vue'),
        meta: {
          authRequired: true,
          section: 'timopen_requests',
          action: 'search',
        },
      },

      {
        path: '/manage/tickets',
        name: 'tickets_search',
        component: () => lazyLoadView(import('@views/tickets/search.vue')),
        meta: {
          authRequired: true,
          section: 'tickets',
          action: 'search',
        },
      },
      {
        path: '/manage/tickets/create',
        name: 'tickets_create',
        component: () => lazyLoadView(import('@views/tickets/create.vue')),
        meta: {
          authRequired: true,
          section: 'tickets',
          action: 'create',
          denyAction: 'create_custom'
        },
      },
      {
        path: '/manage/tickets/create/:key',
        name: 'tickets_create_params',
        component: () => lazyLoadView(import('@views/tickets/create.vue')),
        meta: {
          authRequired: true,
          section: 'tickets',
          action: 'create_custom',
        },
      },
      {
        path: '/manage/tickets/:id',
        name: 'tickets_detail',
        component: () => import('@views/tickets/details.vue'),
        meta: {
          authRequired: true,
          section: 'tickets',
          action: 'read',
        },
      },
      {
        path: '/manage/assets/products/create',
        name: 'products_create',
        component: () => lazyLoadView(import('@views/products/create.vue')),
        meta: {
          authRequired: true,
          section: 'products',
          action: 'create',
        },
      },
      {
        path: '/manage/assets/products',
        name: 'products_search',
        component: () => lazyLoadView(import('@views/products/search.vue')),
        meta: {
          authRequired: true,
          section: 'customers.assets',
          action: 'create',
        },
      },
      {
        path: '/manage/assets/products/:id',
        name: 'products_detail',
        component: () => import('@views/products/details.vue'),
        meta: {
          authRequired: true,
          section: 'products',
          action: 'read',
        },
      },
      {
        path: '/manage/cdc',
        name: 'cdc_iframe',
        component: () => lazyLoadView(import('@views/cdc/index.vue')),
        meta: {
          authRequired: true,
          section: 'customers.assets',
          action: 'search',
        },
      },
      {
        path: '/manage/assets',
        name: 'assets_search',
        component: () => lazyLoadView(import('@views/assets/search.vue')),
        meta: {
          authRequired: true,
          section: 'customers.assets',
          action: 'search',
        },
      },
      {
        path: '/manage/assets/create',
        name: 'assets_create',
        component: () => lazyLoadView(import('@views/assets/create.vue')),
        props: (route) => ({
          replacementOf: route.query.replacementOf,
          ticketId: route.query.ticketId,
          customerId: route.query.customerId,
          customerUserId: route.query.customerUserId,
          plansSubscriptionId: route.query.plansSubscriptionId,
        }),
        meta: {
          authRequired: true,
          section: 'customers.assets',
          action: 'create',
        },
      },
      {
        path: '/manage/assets/:id',
        name: 'assets_detail',
        component: () => import('@views/assets/details.vue'),
        meta: {
          authRequired: true,
          section: 'customers.assets',
          action: 'read',
        },
      },
      {
        path: '/manage/sessions',
        name: 'sessions_pending',
        component: () => lazyLoadView(import('@views/sessions/pending.vue')),
        meta: {
          authRequired: true,
          section: 'sessions',
          action: 'search',
        },
      },
      {
        path: '/manage/sessions/search',
        name: 'sessions_search',
        component: () => lazyLoadView(import('@views/sessions/search.vue')),
        meta: {
          authRequired: true,
          section: 'sessions',
          action: 'search',
        },
      },
      {
        path: '/manage/sessions/:id',
        name: 'sessions_manage',
        component: () => import('@views/sessions/details.vue'),
        meta: {
          authRequired: true,
          section: 'sessions',
          action: 'video',
          async beforeResolve(routeTo, routeFrom, next) {
            await store.dispatch('sessions/setCurrent', routeTo.params.id)
            if (store.getters['sessions/getCurrent']) {
              return next()
            }
            next({
              name: 'sessions_manage',
            })
          },
        },
      },
      {
        path: '/manage/sessions/:id/mobile/:dest',
        name: 'sessions_mobile',
        component: () =>
          lazyLoadView(import('@views/sessions/remoteaccess_mobile.vue')),
        meta: {
          authRequired: true,
          section: 'sessions',
          action: 'remote',
          async beforeResolve(routeTo, routeFrom, next) {
            await store.dispatch('sessions/setCurrent', routeTo.params.id)
            if (store.getters['sessions/getItem']) {
              return next()
            }
            next({
              name: 'sessions',
            })
          },
        },
      },

      // gestione progetti
      {
        path: '/manage/admin/projects',
        name: 'admin.projects',
        component: () => import('@views/admin/projects/search.vue'),
        meta: {
          authRequired: true,
          section: 'admin.projects',
          action: 'search',
        },
      },
      {
        path: '/manage/admin/projects/create',
        name: 'admin.projects_create',
        component: () => import('@views/admin/projects/create.vue'),
        meta: {
          authRequired: true,
          section: 'admin.projects',
          action: 'create',
        },
      },
      // gestione piani
      {
        path: '/manage/admin/plans',
        name: 'plans_search',
        component: () => import('@views/admin/plans/search.vue'),
        meta: {
          authRequired: true,
          section: 'plans',
          action: 'create',
        },
      },
      {
        path: '/manage/admin/plans/create',
        name: 'plans_create',
        component: () => import('@views/admin/plans/create.vue'),
        meta: {
          authRequired: true,
          section: 'plans',
          action: 'create',
        },
      },

      // gestione users
      {
        path: '/manage/admin/users',
        name: 'admin.users',
        component: () => import('@views/admin/users/search.vue'),
        meta: {
          authRequired: true,
          section: 'admin.users',
          action: 'search',
        },
      },
      {
        path: '/manage/admin/users/create',
        name: 'users_create',
        component: () => lazyLoadView(import('@views/admin/users/create.vue')),
        meta: {
          authRequired: true,
          section: 'admin.users',
          action: 'create',
        },
      },
      {
        path: '/manage/admin/users/permissions',
        name: 'users_permissions',
        component: () =>
          lazyLoadView(import('@views/admin/users/permissions.vue')),
        meta: {
          authRequired: true,
          section: 'admin.permissions',
          action: 'update',
        },
      },
      {
        path: '/manage/profile',
        name: 'profile',
        component: () => import('@views/profile.vue'),
        meta: {
          authRequired: true,
        },
        props: (route) => ({ user: store.state.auth.currentUser || {} }),
      },
      {
        path: '/manage/update_password',
        name: 'update_password',
        component: () =>
          lazyLoadView(import('@views/admin/users/updatePassword.vue')),
        meta: {
          authRequired: true,
        },
      },

      // gestione ticket properties
      {
        path: '/manage/settings/tickets_activities',
        name: 'ticket_activities_search',
        component: () =>
          import('@views/settings/tickets_activities/search.vue'),
        meta: {
          authRequired: true,
          section: 'tickets_activities',
          action: 'create',
        },
      },
      {
        path: '/manage/settings/tickets_statuses',
        name: 'ticket_statuses_search',
        component: () => import('@views/settings/tickets_statuses/search.vue'),
        meta: {
          authRequired: true,
          section: 'tickets_statuses',
          action: 'create',
        },
      },
      {
        path: '/manage/settings/tickets_resolutions',
        name: 'tickets_resolutions_search',
        component: () =>
          import('@views/settings/tickets_resolutions/search.vue'),
        meta: {
          authRequired: true,
          section: 'tickets_resolutions',
          action: 'create',
        },
      },
      {
        path: '/manage/settings/agendas',
        name: 'agendas_search',
        component: () => import('@views/settings/agendas/search.vue'),
        meta: {
          authRequired: true,
          section: 'agendas',
          action: 'create',
        },
      },
      {
        path: '/manage/settings/importer',
        name: 'uploaded_files_progress_search',
        component: () => import('@views/settings/uploads/search.vue'),
        meta: {
          authRequired: true,
          section: 'uploaded_files',
          action: 'create',
        },
      },
      {
        path: '/manage/settings/importer/:id',
        name: 'uploaded_files_error_details',
        component: () => import('@views/settings/uploads/details.vue'),
        meta: {
          authRequired: true,
          section: 'uploaded_files',
          action: 'read',
        },
      },
      {
        path: '/manage/nav_entries',
        name: 'nav_entries',
        component: () => lazyLoadView(import('@views/nav_entries/search.vue')),
        meta: {
          authRequired: true,
          section: 'nav_entries',
          action: 'create',
        },
      },
      {
        path: '/manage/settings',
        name: 'settings',
        component: () => lazyLoadView(import('@views/settings/index.vue')),
        meta: {
          authRequired: true,
          section: 'admin.settings',
          action: 'search',
        },
      },
    ],
  },
  {
    path: '/manage/sessions/:id/desktop/:dest',
    name: 'sessions_desktop',
    component: () =>
      lazyLoadView(import('@views/sessions/remoteaccess_desktop.vue')),
    meta: {
      authRequired: true,
      section: 'sessions',
      action: 'remote',
      async beforeResolve(routeTo, routeFrom, next) {
        await store.dispatch('sessions/setCurrent', routeTo.params.id)
        if (store.getters['sessions/getItem']) {
          return next()
        }
        next({
          name: 'sessions',
        })
      },
    },
  },
  {
    path: '/manage/login',
    name: 'login',
    component: () => lazyLoadView(import('@views/login.vue')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) {
          next({ name: 'home' })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/manage/recovery',
    name: 'recovery',
    component: () => lazyLoadView(import('@views/recovery.vue')),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/manage/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom })
      },
    },
  },
]
