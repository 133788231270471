import storeFactory from '@state/template'

const apiResourceName = 'uploads'
const modelName = 'upload'
const model = {
  id: null,
  type: null,
  status: null,
  filename: null,
  filesize: null,
  num_records: null,
  processed: null,
  error: null,
  uploaded_by: null,
  import_started_at: null,
  import_ended_at: null,
}

const filters = {
  filename: null,
  import_ended_at: null,
  import_started_at: null,
  status: null,
  type: null,
  uploaded_by: null,
}

const dropdownScopes = ['dropdown']
const sortDropdownBy = []
const sortDropdownDesc = []

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
