import storeFactory from "@state/template"
import { omit } from "lodash"

const apiResourceName = 'customers/locations'
const modelName = 'sede cliente'
const model = {
  address: null,
  address_2: null,
  cap: null,
  city: null,
  country: null,
  customer_id: null,
  extra: null,
  id: null,
  lat: null,
  lng: null,
  name: null,
  notes: null,
  province: null,
  referent_id: null,
  region: null,
  tags: [],
  distribution_list: [],
}

const filters = {
  customer_id: null,
  referent_id: null,
  city: null,
  name: null,
}

const getLocationText = location => {
  const { name, address, city } = location;
  return `${name} - ${address} ${city}`
}

const getters = {
  validMarkers(state) {
    return state.list.filter(({ lat, lng }) => lat && lng)
  },
  getListDropdown(state) {
    return state.list.map((l) => ({
      value: l.id,
      text: getLocationText(l),
      ...l,
    }))
  },
}

const beforeCreate = state => omit(state.current, ['id'])

const dropdownScopes = ['dropdown']
const sortDropdownBy = ['name']
const sortDropdownDesc = [false]

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  getters,
  beforeCreate,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
