import axios from 'axios'

export const client = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 15000,
})

// error iterceptor
client.interceptors.response.use(
  (response) => {
    if (['arraybuffer', 'blob'].includes(response?.request?.responseType)) {
      return response.data
    }
    return response.data?.data !== undefined
      ? response.data.data
      : response.data
  },
  (error) => {
    if (!error.response) {
      return Promise.reject(error)
    }

    if (error.response.status === 401) {
      console.info('Credentials expired....')
      window.location.href = '/'
    }

    return Promise.reject(error.response.data.error || error.response.data)
  }
)

client.interceptors.request.use((config) => {
  if (config.method === 'POST' || config.method === 'PUT') {
    config.withCredentials = true
    config.xsrfCookieName = 'XSRF-TOKEN'
    config.xsrfHeaderName = 'X-XSRF-TOKEN'
  }
  return config
})

export const setApiAuthToken = (token) => {
  if (token !== null) {
    client.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete client.defaults.headers.common.Authorization
  }
}
/**
 *
 * @param {*} method
 * @param {*} url
 * @param {*} data
 * @param {import('axios').AxiosRequestConfig} config
 */
export const netRequest = async (method, url, data, config = {}) =>
  client({ method, url, data, ...config })
