export default {
  current: {},
  filters: {},
  list: [],
  loading: false,
  loadingCounter: 0,
  pagination: {
    rows: [],
    totalPages: 0,
    totalRows: 0
  },
  scopes: [],
}
