<script>
import axios from 'axios'
import Layout from '@layouts/full.vue'

import LoadingView from './_loading.vue'

export default {
  page: {
    title: 'Timeout Caricamento',
  },
  components: { Layout, LoadingView },
  data() {
    return {
      offlineConfirmed: false,
    }
  },
  beforeCreate() {
    axios
      .head('/health')
      .then(() => {
        window.location.reload()
      })
      .catch(() => {
        this.offlineConfirmed = true
      })
  },
}
</script>

<template>
  <Layout v-if="offlineConfirmed">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col
          cols="12"
          sm="8"
          md="4"
          style="background-color: rgba(0,0,0,.6); border-radius: 2rem;"
        >
          <v-img
            src="@assets/ennova_logotype_negative.svg"
            contain
            height="64"
            class="my-5 text-center"
          />
          <h1 style="color: #fff;" class="text-center">
            <strong>Timeout Error</strong>
          </h1>
          <p class="h2 white--text text-center mt-5">
            Non è stato possibile caricare la pagina richiesta. <br />
            Verificare la propria connessione ad internet!
          </p>
          <p class="h2 white--text text-center mt-5">
            <v-btn href="#" @click="$router.go(-1)">Torna Indietro</v-btn>
          </p>

          <v-spacer class="mt-12" />
        </v-col>
      </v-row>
    </v-container>
  </Layout>
  <LoadingView v-else />
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
