import storeFactory from '@state/template'

const apiResourceName = 'plans'
const modelName = 'piano'

const model = {
  active: true,
  auto_renew: true,
  auto_start: true,
  billable: true,
  billing_cycle: 365,
  channel: null,
  code: null,
  cost_activation: 0.0,
  cost_per_cycle: 0.0,
  cycle: null,
  description: null,
  devices_types: -1,
  duration: 365,
  extra: {},
  max_devices: -1,
  max_devices_per_user: -1,
  max_ticket_per_cycle: -1,
  max_ticket_per_duration: -1,
  max_ticket_per_month: -1,
  max_ticket_per_user: -1,
  name: null,
  options: {},
  project_key: null,
  trial: 0,
  otp_required: false,
  otp_sender: false,
  otp_template: false,
}
const filters = {
  active: true,
  channel: false,
  code: null,
  name: null,
  project_key: [],
}

const sortDropdownBy = ['name']

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  sortDropdownBy,
})
