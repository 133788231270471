import storeFactory from '@state/template'

const apiResourceName = 'customers/assets'
const modelName = 'dispositivo'
const model = {
  activated_at: null,
  assigned_at: null,
  brand: null,
  contract_end_at: null,
  contract_start_at: null,
  created_at: null,
  customer_id: null,
  customer_location_id: null,
  customer_user_id: null,
  deleted_at: null,
  file: null,
  has_kasko: false,
  is_spare: false,
  id: null,
  imei: null,
  model: null,
  notes: null,
  options: {},
  plans_subscription_id: null,
  product_id: null,
  replaced_by: null,
  replacement_of: null,
  associated_with: null,
  serial_code: null,
  status: null,
  system_id: null,
  system_tof_id: null,
  updated_at: null,
}
const filters = {
  customer_id: null,
  brand: null,
  imei: null,
  serial_code: null,
  model: null,
  status: null,
  activated_at: null,
  assigned_at: null,
  query: null,
  product_id: null,
  type: null,
  contract_start_at: {
    start: null,
    end: null,
  },
  contract_end_at: {
    start: null,
    end: null,
  },
  has_kasko: null,
  is_spare: null,
  customer_user_id: null,
}

const state = {
  statuses: [
    'Spedito',
    'Distribuito',
    'Assegnato',
    'Consegnato',
    'Sostituito',
    'In riparazione',
    'Scorta Calda',
    'Furto/Smarrimento',
    'Da Riconsegnare',
  ],
}

const dropdownScopes = ['dropdown']
const sortDropdownBy = ['brand', 'model', 'serial_code']
const sortDropdownDesc = [false, false, false]

const getIdentifier = (asset) => asset.serial_code || asset.imei
const getAssetText = (asset) => {
  return `${asset.brand} ${asset.model} (${getIdentifier(asset)})`
}
const getters = {
  getListDropdown(state) {
    return state.list.map((l) => ({
      value: l.id,
      text: getAssetText(l),
      ...l,
    }))
  },
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  state,
  getters,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
