
import storeFactory from '@state/template'

const apiResourceName = 'customers/users'
const modelName = 'utente'
const model = {
  address: null,
  address_2: null,
  alt_phone_number: null,
  alt_phone_number_2: null,
  cap: null,
  city: null,
  country: null,
  create_login: false,
  customer_id: null,
  customers_location_id: null,
  customers_users_group_id: null,
  email: null,
  file: null,
  first_name: null,
  last_name: null,
  mobile_phone_number: null,
  plans_subscription_id: null,
  platform_user: null,
  province: null,
  region: null,
  role: null,
  role_id: null,
  work_phone_number: null
}

const filters = {
  code: null,
  customer_id: null,
  customers_location_id: null,
  email: null,
  last_name: null,
  role_id: null,
  username: null,
  plans_subscription_id: null,
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
})
