import { cloneDeep } from 'lodash'
import { netRequest } from '@api/client'

const defaultState = {
  loading: false,
  error: null,
  data: {},
}

const getters = {
  dashboardData: (state) => (resource, scope, suffix) => {
    return state.data[`${resource}_${scope}${suffix ? `_${suffix}` : ''}`]
  },
}

const mutations = {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_ERROR_MSG(state, value) {
    state.error = value
  },
  SET_DASHBOARD_DATA(state, data) {
    state.data = {
      ...state.data,
      ...data,
    }
  },
}

const actions = {
  async getDataFromModel(
    { commit, state, rootState },
    { resource, scope, count = false, filters = {}, suffix }
  ) {
    try {
      commit('SET_LOADING', true)
      const data = await netRequest('POST', `${resource}/search`, {
        filters,
        scopes: [`dashboard_${scope}`],
        ctx: {
          flat: true,
          count,
        },
      })

      const idx = `${resource}_${scope}${suffix ? `_${suffix}` : ''}`
      commit('SET_DASHBOARD_DATA', { [idx]: data })
    } catch (err) {
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async getReportData(
    { commit, state, rootState },
    // eslint-disable-next-line camelcase
    { resource = 'report', scope, interval, customer_id, suffix }
  ) {
    try {
      commit('SET_LOADING', true)
      const data = await netRequest('GET', `dashboard/report`, null, {
        params: {
          type: scope,
          interval,
          customer_id,
        },
      })

      const idx = `${resource}_${scope}${suffix ? `_${suffix}` : ''}`
      commit('SET_DASHBOARD_DATA', { [idx]: data })
    } catch (err) {
    } finally {
      commit('SET_LOADING', false)
    }
  },
}

export default {
  state: cloneDeep(defaultState),
  getters,
  mutations,
  actions,
}
