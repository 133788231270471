<script>
import Layout from '@layouts/public.vue'

export default {
  page: {
    title: '404',
    meta: [{ name: 'description', content: '404' }],
  },
  components: { Layout },
}
</script>

<template>
  <Layout>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col>
          <h1 class="text-center text-h1"> 404 </h1>
          <h2 class="text-center text-h4">
            <strong>Not found</strong>
          </h2>
          <p class="h2 text-center mt-5">
            La pagina richiesta non esiste oppure è stata spostata. <br />
            Verifica i dati inseriti
          </p>
          <p class="h2 white--text text-center mt-5">
            <v-btn-toggle>
              <v-btn class="px-5" @click="$router.go(-1)">
                Torna Indietro
              </v-btn>
            </v-btn-toggle>
          </p>
          <v-spacer class="mt-12" />
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
