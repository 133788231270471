import { getBrowserFingeprint } from '@utils/browser'
import { setApiAuthToken, netRequest } from '@api/client'
import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules'
import { get, identity } from 'lodash'
import storeFactory from '@state/template'

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

const apiResourceName = 'auth'
const modelName = ' '

/**
 * This is a mapped version that does not really correspond to the user in the db
 * It is only used for the logged in user
 */
const model = {
  user: {
    id: null,
    type: null,
    username: null,
    first_name: null,
    last_name: null,
    email: null,
    mobile_phone_number: null,
    work_phone_number: null,
    is_temp_password: false,
    profile_picture: null,
  },
  role: {
    name: null,
    key: null,
    permissions: {},
  },
  device: {
    id: null,
    device_manufacturer: null,
    device_model: null,
  },
  token: null,
}

const state = {
  current: getSavedState('auth.currentUser') || model,
}

/**
 * FOR THIS MODULE ONLY PAGINATION POINTS TO THE USER DEVICES,
 * NOT A LIST OF USERS WHICH WOULD NOT MAKE SENSE
 */
const getSearchUrl = () => `${apiResourceName}/devices`

/**
 * Since the current user is mapped differently from the common user
 * this points to a specific endpoint
 */
const getCurrentUrl = () => `/${apiResourceName}/profile`

/**
 * This needs to flatten the object because update() is
 * the same for both common users and current user
 */
const beforeUpdate = (state) => {
  return {
    ...state.current,
    ...state.current.user,
  }
}

/** This matches an arbitrary choice in back end */
const getUpdateUrl = () => `/${apiResourceName}/profile`

export const getters = {
  /**
   * This only checks that a user is in "current" but it
   * might also be stagnant data from previous sessions
   */
  loggedIn: (state) => {
    return !!state.current.user.id
  },
  getCurrentUsername(state) {
    return state?.current?.user?.username
  },
  userType: (state) => {
    if (state?.current?.customer?.id) return 'customer'
    if (state?.current?.dealer?.id) return 'dealer'
    return 'admin'
  },
  canUser: (state) => (section, action) => {
    return !!state.current?.role?.permissions?.[section]?.[action]
  },
  denyUser: (state) => (section, denyAction) => {
    return !!state.current?.role?.permissions?.[section]?.[denyAction]
  },
  // Understands if in the passed section the user can do anything
  canUserSee: (state) => (section) => {
    return Object.values(
      get(state, ['current', 'role', 'permissions', section], {})
    ).some(identity)
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  async init({ state, dispatch, getters }) {
    setApiAuthToken(state.current?.token)
    await dispatch('getCsrfToken')
    dispatch('validate')

    if (getters.loggedIn) dispatchActionForAllModules('preload')
  },

  // Logs in the current user.
  async logIn(
    { commit, dispatch, getters },
    { username, password, authCode, provider } = {}
  ) {
    commit('SET_LOADING')

    try {
      const fingerprint = await getBrowserFingeprint()
      let user = null

      if (authCode && provider) {
        user = await netRequest('POST', `/${apiResourceName}/sso`, {
          authCode,
          provider,
          fingerprint,
        })
      } else {
        user = await netRequest('POST', `/${apiResourceName}`, {
          username,
          password,
          fingerprint,
        })
      }
      commit('SET_CURRENT', user)
      saveState('auth.currentUser', user)
      setApiAuthToken(user.token)
      dispatchActionForAllModules('preload')
      commit('RESET_LOADING')
      return user
      // If an error happens propagate it
    } catch (err) {
      commit('RESET_LOADING')
      throw err
    }
  },

  // Logs out the current user.
  logOut({ commit }) {
    commit('RESET_CURRENT')
    saveState('auth.currentUser', model)
    setApiAuthToken(null)
  },

  async validate({ commit, state, getters }) {
    if (!getters.loggedIn) return

    try {
      const user = await netRequest('get', `/${apiResourceName}`)

      commit('SET_CURRENT', user)
      saveState('auth.currentUser', user)
      setApiAuthToken(user.token)
      return user
    } catch (error) {
      commit('RESET_CURRENT')
      saveState('auth.currentUser', model)
      setApiAuthToken(null)
      return null
    }
  },

  async updatePassword({ commit, getters }, password) {
    if (!getters.loggedIn) return Promise.resolve(null)

    try {
      commit('SET_LOADING')
      await netRequest('PUT', '/auth/password', { password })
    } catch (error) {
      if (error && error.statusCode === 401) {
        commit('RESET_CURRENT')
      }
      throw error
    } finally {
      commit('RESET_LOADING')
    }
  },

  async getCsrfToken({ commit }) {
    try {
      commit('SET_LOADING')
      const { csrfToken } = await netRequest('get', `/${apiResourceName}/csrf`)
      return csrfToken
    } catch (err) {
      return null
    } finally {
      commit('RESET_LOADING')
    }
  },
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  state,
  getSearchUrl,
  getCurrentUrl,
  beforeUpdate,
  getUpdateUrl,
  getters,
  actions,
})
