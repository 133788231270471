import storeFactory from '@state/template'

const apiResourceName = 'admin/users_roles_operations'
const modelName = 'operazione'
const model = {
  default_value: false,
  key: null,
  label: null,
  section: null
}
const filters = {
  key: null,
  label: null,
  default_value: null,
  section: null,
}

const mutations = {
  SET_KEY_FROM_LABEL(state, label) {
    state.current.key = label.toLowerCase().replace(/\s/g, '_')
  },
}


const dropdownScopes = []
const sortDropdownBy = ['label']
const sortDropdownDesc = [false]


export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  mutations,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
