/* eslint-disable camelcase */
import { pick, merge, cloneDeep, get, identity, isString, isArray, isEmpty, matchesProperty } from 'lodash'
import { netRequest } from '@api/client'
import storeFactory from '@state/template'

const apiResourceName = 'sessions'
const modelName = 'sessione'

const model = {
  client_code: null,
  customer_address: null,
  customer_name: null,
  duration: 0,
  external_id: null,
  notes: null,
  project_key: null,
  rating: 0,
  resolution: null,
  scheduled_at: null,
  session_code: null,
  status: 'pending',
  type: 'video',
}
const ot_session_model = {
  recording: false,
  token: null,
}
const filters = {
  created_at: null,
  customer_address: null,
  customer_name: null,
  project_key: [],
  resolution: [],
  scheduled_at: null,
  status: [],
  type: null,
}
const state = {

  env: {
    app_android_url: null,
    app_ios_url: null,
    app_osx_url: null,
    app_win_url: null,
    disclaimer_show: true,
    disclaimer_text: 'Termini e condizioni',
    disclaimer_url: null,
    hasRemote: false,
    hasVideo: false,
    key: 'EQS',
    name: 'Ennova QuickSupport',
  },
  ot_session: cloneDeep(ot_session_model),
  pending: false,
  statuses: [],
  resolutions: [],
  types: [
    { text: 'Remoto', value: 'remote' },
    { text: 'Video', value: 'video' },
  ],
}
const dropdownScopes = ['dropdown']
const sortDropdownBy = ['serial']
const sortDropdownDesc = [true]

const beforeCreate = (state) =>
  pick(state.current, [
    'type',
    'project_key',
    'customer_address',
    'external_id',
  ])

const beforeUpdate = (state) =>
  pick(state.current, [
    'customer_address',
    'customer_name',
    'status',
    'resolution',
    'notes',
    'external_id',
    'duration',
  ])

const getters = {
  // a human readable map { closed_ok: 'Chiuso OK'} so it can be used in tables
  statusesMap: state => state.statuses.reduce((acc, { value, text }) => ({ ...acc, [value]: text }), {}),
  resolutionsMap: state => state.resolutions.reduce((acc, { value, text }) => ({ ...acc, [value]: text }), {}),
  typesMap: state => state.types.reduce((acc, { value, text }) => ({ ...acc, [value]: text }), {}),
  closingStatuses: state => state.statuses.filter(s => s.closing),

  resolutionsByStatus: (state) => (status) => {
    let iteratee = identity;
    if (isString(status)) iteratee = matchesProperty('status', status);
    if (isArray(status) && !isEmpty(status)) iteratee = r => status.includes(r.status)

    return state.resolutions.filter(iteratee)
  },
  settings(state) {
    return get(state, ['current', 'project', 'settings'], {})
  },
  getSessionId(state) {
    /* eslint-disable camelcase */
    const { project_key, session_code, client_code } = state.current
    return `${project_key}-${session_code}-${client_code}`
  },
  getOTSessionInfo(state) {
    return {
      session_id: state.current.ot_session_id,
      ...state.ot_session,
    }
  },
  getSessionClosed(state) {
    return state.current.state !== 'closed'
  },
}

const mutations = {
  SET_STATUSES(state, value) {
    state.statuses = value
  },
  RESET_STATUSES(state) {
    state.statuses = []
  },
  SET_RESOLUTIONS(state, value) {
    state.resolutions = value
  },
  RESET_RESOLUTIONS(state) {
    state.resolutions = []
  },
  SET_PENDING(state, value) {
    state.pending = value
  },
  SET_OT_SESSION(state, value) {
    state.ot_session = merge({}, state.ot_session, value)
  },
  RESET_OT_SESSION(state) {
    state.ot_session = cloneDeep(ot_session_model)
  },
  SET_ENV(state, data) {
    state.env = data
  },
}
const actions = {
  async preload({ dispatch }) {
    return Promise.all([dispatch('getStatuses'), dispatch('getResolutions')])
  },
  async getStatuses({ commit }) {
    try {
      commit('SET_LOADING')
      const data = await netRequest('GET', `${apiResourceName}/statuses`)
      commit('SET_STATUSES', data)
    } catch (err) {
      this._vm.$dialog.notify.warning(
        'Errore durante il recupero degli stati sessione.',
        {
          position: 'bottom-right',
          timeout: 2000,
        }
      )
    } finally {
      commit('RESET_LOADING')
    }
  },
  async getResolutions({ commit }) {
    try {
      commit('SET_LOADING')
      const data = await netRequest('GET', `${apiResourceName}/resolutions`)
      commit('SET_RESOLUTIONS', data)
    } catch (err) {
      this._vm.$dialog.notify.warning(
        'Errore durante il recupero degli esiti sessione.',
        {
          position: 'bottom-right',
          timeout: 2000,
        }
      )
    } finally {
      commit('RESET_LOADING')
    }

  },
  async getSessionInfo({ commit }, { project, sid }) {
    commit('SET_LOADING')
    let data = null
    try {
      data = await netRequest('POST', `../quicksupport/video/${project}/${sid}`)
    } catch (err) {
      console.error('Errore sessione: ', err.message)
      this._vm.$dialog.notify.error('Attenzione, si è verificato un errore.', {
        position: 'bottom-right',
        timeout: 2000,
      })
    } finally {
      commit('RESET_LOADING')
    }
    return data
  },
  async modify({ commit, state, dispatch }, updateKey) {
    const updateMap = {
      duration: {
        duration: state.current.duration,
      },
      suspend: {
        status: 'suspended',
        resolution: 'suspended',
        duration: state.current.duration,
        extend: 30,
      },
      terminate: {
        status: 'closed',
        resolution: 'closed_ok',
        duration: state.current.duration,
      },
    }
    const messageMap = {
      duration: 'Durata estesa correttamente',
      suspend: 'Sessione sospesa correttamente',
      terminate: 'Sessione completata correttamente',
    }

    let data
    try {
      commit('SET_LOADING')
      data = await netRequest(
        'PUT',
        `${apiResourceName}/${state.current.id}`,
        updateMap[updateKey]
      )
      dispatch('notifications/info', messageMap[updateKey], { root: true })
    } catch (err) {
      dispatch('notifications/error', `Errore durante il salvataggio`, {
        root: true,
      })
      console.error(err)
    } finally {
      commit('RESET_LOADING')
    }
    return data
  },
  async suspendSession({ dispatch }) {
    return dispatch('modify', 'suspend')
  },
  async saveSessionDuration({ dispatch }) {
    return dispatch('modify', 'duration')
  },

  async terminateSession({ dispatch }) {
    return dispatch('modify', 'terminate')
  },

  async notifyVideo({ commit, state, dispatch }) {
    let data
    try {
      commit('SET_LOADING')
      data = await netRequest(
        'POST',
        `sessions/${state.current.id}/notifyVideo`,
        {}
      )
    } catch (err) {
      this._vm.$dialog.notify.warning(
        'Attenzione, si è verificato un errore.',
        {
          position: 'bottom-right',
          timeout: 2000,
        }
      )
      console.warn('Notify video error', err)
    } finally {
      commit('RESET_LOADING')
    }
    return data
  },

  async setRecording({ commit, state, dispatch }, action) {
    commit('SET_LOADING')
    try {
      await netRequest(
        'POST',
        `sessions/${state.current.id}/setSessionArchive`,
        { action }
      )

      const actionMap = {
        start: { text: 'avviata', recording: true },
        stop: { text: 'terminata', recording: false },
      }

      this._vm.$dialog.notify.success(
        `Registrazione sessione ${actionMap[action].text}`,
        {
          position: 'bottom-right',
          timeout: 2000,
        }
      )

      commit('SET_OT_SESSION', { recording: actionMap[action].recording })
    } catch (err) {
      this._vm.$dialog.notify.warning(
        'Attenzione, si è verificato un errore.',
        {
          position: 'bottom-right',
          timeout: 2000,
        }
      )
    } finally {
      commit('RESET_LOADING')
    }
  },

  async getSessionToken({ commit, state, rootState }, role = 'moderator') {
    try {
      commit('SET_LOADING')
      const data = await netRequest(
        'POST',
        `${apiResourceName}/${state.current.id}/token`,
        {
          role,
        }
      )
      commit('SET_OT_SESSION', data)
    } catch (err) {
      console.warn('Unable to get token...')
      this._vm.$dialog.notify.warning(
        'Attenzione, si è verificato un errore.',
        {
          position: 'bottom-right',
          timeout: 2000,
        }
      )
    } finally {
      commit('RESET_LOADING')
    }
  },

  async getEnvData({ commit }) {
    try {
      commit('SET_LOADING')
      const env = await netRequest('GET', '../quicksupport/env')
      commit('SET_ENV', env)

    } catch (err) {
      console.warn('Unable to get env data...')
      this._vm.$dialog.notify.warning(
        'Attenzione, si è verificato un errore.',
        {
          position: 'bottom-right',
          timeout: 2000,
        }
      )
    } finally {
      commit('RESET_LOADING')
    }
  },
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  state,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
  getters,
  mutations,
  beforeCreate,
  beforeUpdate,
  actions,
})
