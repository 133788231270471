var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('v-row',{class:{ 'fill-height': _vm.$vuetify.breakpoint.mdAndUp }},[_c('v-col',{staticClass:"white d-flex align-center flex-column",attrs:{"cols":"12","md":"4"}},[_c('div',{class:{
            'mt-16': _vm.$vuetify.breakpoint.mdAndUp,
            'mt-8': !_vm.$vuetify.breakpoint.mdAndUp,
            ' mb-auto': true,
            'text-center': true,
          }},[_c('FrontpageLogo')],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('LoginArt'):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-img',{staticClass:"mt-4 mb-4",attrs:{"src":require("@assets/ennova_logotype.svg"),"contain":"","width":"200","height":"48"}})],1)],1),_c('v-col',{staticClass:"pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column"},[_c('div',{staticClass:"ma-auto",style:({
            width: _vm.$vuetify.breakpoint.mdAndUp ? '480px' : '96%',
            'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '480px' : '96%',
          })},[_vm._t("default")],2),_c('v-subheader',{staticClass:"align-start font-weight-light"},[_vm._v(" v"+_vm._s(_vm.version)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }