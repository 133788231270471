import Vue from 'vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import 'moment/locale/it'

Vue.use(VueMoment, {
  moment,
})

Vue.filter('momentOr', (date, format, fallback) => {
  if (date) return moment(date).format(format);
  else return fallback
})
