<script>
import Layout from '@layouts/public.vue'

export default {
  page: {
    title: '403',
    meta: [{ name: 'description', content: '403' }],
  },
  components: { Layout },
}
</script>

<template>
  <Layout>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col>
          <h1 class="text-center text-h1"> 403 </h1>
          <h2 class="text-center text-h4">
            <strong>Non autorizzato</strong>
          </h2>
          <p class="h2 text-center mt-5">
            Impossibile accedere alla pagina corrente:<br />
            assicurati di avere i privilegi necessari o di utilizzare l'utenza
            corretta.
          </p>
          <p class="h2 white--text text-center mt-5">
            <v-btn-toggle>
              <v-btn href="/manage" class="px-5"> Torna Indietro </v-btn>

              <v-btn href="/manage/logout" color="primary" class="px-5">
                Esci e cambia utenza
              </v-btn>
            </v-btn-toggle>
          </p>
          <v-spacer class="mt-12" />
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
