import storeFactory from '@state/template'
import {
  intersection,
  isString,
  isArray,
  isEmpty,
  overEvery,
  isBoolean,
  matchesProperty,
  overSome,
} from 'lodash'

const apiResourceName = 'tickets/statuses'
const modelName = 'stato'

const model = {
  active: true,
  categories: [],
  closing: false,
  code: null,
  color: '#558B2FFF',
  extra: {},
  macros: [],
  opening: true,
  reschedulable: true,
  revocable: true,
  suspend: false,
  system: false,
}

const filters = {
  active: null,
  categories: [],
  code: null,
  macros: [],
}

const By = {
  /** strictCode and code differ by the fact that an empty search
   * will return nothing in strict and everything in the other */
  strictCode: (code) => (status) => {
    let pass = false
    if (isString(code)) pass = code === status.code
    else if (isArray(code) && !isEmpty(code)) {
      pass = code.includes(status.code)
    }
    return pass
  },
  code: (code) => (status) => {
    let pass = true
    if (isString(code)) pass = code === status.code
    else if (isArray(code) && !isEmpty(code)) {
      pass = code.includes(status.code)
    }
    return pass
  },
  macro: (macro) => (status) => {
    let pass = true
    // Only check for statuses that are bound, unrestricted statuses will always be present
    if (status.macros.length) {
      if (isString(macro)) pass = status.macros.includes(macro)
      else if (isArray(macro) && !isEmpty(macro)) {
        pass = !!intersection(macro, status.macros).length
      }
    }

    return pass
  },
  category: (category) => (status) => {
    let pass = true
    // Only check for statuses that are bound, unrestricted statuses will always be present
    if (status.categories.length) {
      if (isString(category)) pass = status.categories.includes(category)
      else if (isArray(category) && !isEmpty(category)) {
        pass = !!intersection(category, status.categories).length
      }
    }
    return pass
  },
  resolution: (resolution) => (status) => {
    let pass = true
    if (isArray(resolution) && !isEmpty(resolution)) {
      // If the passed res are not linked to any status or the status matches the current, pass
      pass = resolution.some(
        overSome([
          matchesProperty('status', null),
          matchesProperty('status', status.code),
        ])
      )
    }
    return pass
  },
}

const dropdownMapper = (status) => ({
  text: status.code,
  value: status.code,
  disabled: !status.active,
})

const getters = {
  /**
   * Passed an obj that hold props as filters it will return activities that comply with the filters.
   * Every prop must be either string or array to be used, otherwise it will be ignored.
   */
  byContext: (state, getters, rootState, rootGetters) => (context = {}) => {
    const { code, macro, category, resolution } = context
    const strict = isBoolean(context.strict) ? context.strict : false
    return state.list.filter(
      overEvery([
        By.macro(macro),
        By.category(category),
        (strict ? By.strictCode : By.code)(code),
        By.resolution(
          /**
           * This has to be strict because we do not want resolutions that are not
           * purposefully selected because statuses that are not
           * associated with resolutions would not pass if no res has been selected
           */
          rootGetters['ticketsResolutions/byContext']({
            code: resolution,
            strict: true
          })
        ),
      ])
    )
  },
  statusesByContext: (state, getters) => (context) =>
    getters.byContext(context).map(dropdownMapper),
  getStatuses(state) {
    return state.list.map(dropdownMapper)
  },
}

const actions = {
  async preload({ dispatch }) {
    return dispatch('getDropdownList')
  },
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  getters,
  actions,
})
