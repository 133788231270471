import storeFactory from '@state/template'

const apiResourceName = 'admin/users_permissions_sections'
const modelName = 'sezione'

const model = {
  key: null,
  label: null,
  restrictions: null,
}

const filters = {
  key: null,
  label: null
}

const mutations = {
  SET_KEY_FROM_LABEL(state, label) {
    state.current.key = label.toLowerCase().replace(/\s/g, '_')
  },
}

const dropdownScopes = []
const sortDropdownBy = ['label']
const sortDropdownDesc = [false]


const getters = {
  /**
   * Tests whether the operation is possible over a certain element
   */
  isOperationPossible: (state) => (sectionKey, operationKey) => {
    // If no element has been loaded yet, return false
    if (!state.list.length) {
      return false
    }
    const targetElement = state.list.find((el) => el.value === sectionKey)
    // If there is a restriction for this operation find it
    const operationRestrictions = targetElement?.restrictions?.find(
      (el) => el === operationKey
    )
    return !operationRestrictions
  },
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  mutations,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
  getters,
})
