const projects = Object.freeze({
  DEFAULT: 'ONESOLUTION',
  CWM: 'cwm',
  KNOX: 'knox services',
  TUTTOSMART: 'tuttosmart',
  EQS: 'eqs',
  ENI: 'portale selfcare',
  TIM: 'TIM Enterprise',
})

export default {
  computed: {
    projects() {
      return projects
    },
    webenv() {
      const { hostname } = window.location

      if (hostname.indexOf('cwm') > -1) return projects.CWM

      if (
        hostname.indexOf('mdm.windtrebusiness.it') > -1 ||
        hostname.indexOf('knox') > -1
      )
        return projects.KNOX

      if (hostname.indexOf('tuttosmart') > -1) return projects.TUTTOSMART
      if (hostname.indexOf('eqs') > -1) return projects.EQS
      if (hostname.indexOf('eni') > -1) return projects.ENI
      if (hostname.indexOf('tim') > -1) return projects.TIM

      return projects.DEFAULT
    },

    visualSettings() {
      const defaults = {
        loginBackground: require('@assets/images/background_login.jpg'),
        appbarColor: '#FFFFFF',
        appbarDark: false,
        footerVisible: false,
        footerColor: '#004691',
        footerDark: true,
        footerHeight: '120vh',
        frontPageTitle: '<strong>ONE</strong>SOLUTION',
        frontPageSubtitle: 'Powered by Ennova',
        frontPageBoxTitle: 'Accedi',
        accent: 'primary',
        secondary: 'blue',
      }

      switch (this.webenv) {
        case projects.KNOX:
          return {
            ...defaults,
            loginBackground: require('@assets/images/windtre_bg_login.jpg'),
            appbarColor: '#08080D',
            appbarDark: true,
            footerVisible: true,
            footerColor: '#58595b',
            footerDark: true,
            footerHeight: '120vh',
            frontPageTitle: 'DIGITAL SOLUTIONS',
            frontPageSubtitle:
              'Ottimizza la produttività del tuo business e migliora le relazioni commerciali con i tuoi clienti',
            frontPageBoxTitle: 'Knox Manage Plus',
            accent: 'orange darken-3',
            secondary: 'grey darken-2',
            displayCta: true,
          }

        case projects.ENI:
          return {
            ...defaults,
            appbarColor: '#FFFFFF',
            appbarDark: true,
            frontPageTitle: 'TIM per ENI',
            frontPageSubtitle:
              'Supporto completo per la gestione, la sicurezza e l’assistenza degli smartphone della tua azienda.',
            footerVisible: true,
          }

        case projects.TUTTOSMART:
          return {
            ...defaults,
            appbarColor: '#FFFFFF',
            appbarDark: true,
            frontPageTitle: 'TUTTO SMART',
            frontPageSubtitle:
              'Supporto completo per la gestione, la sicurezza e l’assistenza degli smartphone della tua azienda.',
            footerVisible: true,
          }

        case projects.TIM:
          return {
            ...defaults,
            appbarColor: '#FFFFFF',
            appbarDark: true,
            frontPageTitle: 'TIM Enterprise',
            frontPageSubtitle:
              'Supporto completo per la gestione, la sicurezza e l’assistenza degli smartphone della tua azienda.',
            footerVisible: true,
          }

        case projects.CWM:
          return {
            ...defaults,
            appbarColor: '#FFFFFF',
            appbarDark: true,
            frontPageTitle: '<strong>cloud</strong>workplace',
            frontPageSubtitle: 'm a n a g e m e n t',
            footerVisible: true,
          }

        default:
          return defaults
      }
    },

    isMagicLink() {
      return this.$route.params.magic_link
    },
  },
}
