export default {
  state: {
    message: {
      text: '',
      active: false
    }
  },
  getters: {},
  mutations: {},
  actions: {
    async success({ commit }, text) {
      await this._vm.$dialog.notify.info(text)
    },
    async warning({ commit }, text) {
      await this._vm.$dialog.notify.warning(text)
    },
    async error({ commit }, text) {
      await this._vm.$dialog.notify.error(text)
    },
    async info({ commit }, text) {
      await this._vm.$dialog.notify.info(text)
    },
  },
}
