import { get } from "lodash"
import Vue from 'vue'
import Vue2Filters from 'vue2-filters'

Vue.use(Vue2Filters)

Vue.filter('duration', function (value = 0) {
  const date = new Date(0)
  date.setSeconds(value) // specify value for SECONDS here
  return date.toISOString().substr(11, 8)
})

Vue.filter('get', get)
