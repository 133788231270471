import storeFactory from '@state/template'

const apiResourceName = 'tickets/history'
const modelName = 'storico'

const model = {
  id: null,
  description: null,
  value: null,
  extra: null,
  logged_at: null,
  ticket_id: null,
  user: null,
}

const filters = {
  ticket_id: null,
  logged_at: null,
  user: null,
}

const dropdownScopes = ['dropdown']
const sortDropdownBy = ['name']
const sortDropdownDesc = [false]

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
