import Vue from 'vue'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import router from '@router'
import store from '@state/store'
import VuetifyDialog from 'vuetify-dialog'
import App from './app.vue'
// Globally register all `_base`-prefixed components
import '@components/_globals'
// import plugins
import vuetify from './plugins/vuetify'

import './plugins/vee-validate'
import './plugins/vue-moment'
import './plugins/algolia-places'
import './plugins/vue2-filters'
import './plugins/v-mask'
import './plugins/leaflet'
import './plugins/components'
import './plugins/clipboard'
import './plugins/video-tfjs'
import './plugins/google-auth.js'
// import './plugins/sentry.js'

import './registerServiceWorker'

import 'vuetify-dialog/dist/vuetify-dialog.css'

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
  confirm: {
    actions: {
      false: 'NO',
      true: {
        text: 'SI',
        color: 'primary',
      },
    },
  },
})

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
