import Vue from 'vue'
import VueFriendlyIframe from 'vue-friendly-iframe'
import VueChatScroll from 'vue-chat-scroll'
import linkify from 'vue-linkify'
import Vidle from 'v-idle'

import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

import VueSimpleMarkdown from 'vue-simple-markdown'
import vuetify from './vuetify'
import 'tiptap-vuetify/dist/main.css'
// import 'vue-simple-markdown/dist/vue-simple-markdown.css'

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md',
})

Vue.use(Chartkick.use(Chart))
Vue.use(Vidle)
Vue.use(VueFriendlyIframe)
Vue.use(VueChatScroll)
Vue.use(VueSimpleMarkdown)
Vue.directive('linkified', linkify)
