import storeFactory from '@state/template'

const model = {
  id: null,
  skill_type: null,
  label: null
}

const modelName = 'Motivo'
const apiResourceName = 'virtualagents/reasons'

const filters = {
  id: null,
  skill_type: null
}

export default storeFactory({
  model,
  modelName,
  apiResourceName,
  filters,
})
