<script>
import Layout from '@layouts/main.vue'

export default {
  page: {
    title: 'Caricamento in corso...',
  },
  components: { Layout },
}
</script>

<template>
  <Layout>
    <Transition appear>
      <v-overlay>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </Transition>
  </Layout>
</template>
