import { omit } from 'lodash'
import storeFactory from '@state/template';

const model = {
  key: null,
  name: null,
  prefix: 43,
  customer: null,
  capabilities: {
    video: false,
    remote_mobile: false,
    remote_desktop: false,
  },
  settings: {
    agent_audio: true,
    agent_audio_autostart: true,
    agent_share_screen: true,
    agent_video: true,
    agent_video_autostart: true,
    agent_video_effects: false,

    customer_audio: true,
    customer_audio_autostart: true,
    customer_share_screen: false,
    customer_video: true,
    customer_video_autostart: false,

    app_android_url: null,
    app_ios_url: null,
    app_osx_url: null,
    app_win_url: null,

    mail_sender_name: 'QuickSupport',
    sms_sender: 'Ennova QS',

    qs_mail_subject: 'Nuova sessione creata',
    qs_mail_template_name: null,
    qs_sms_template: null,
    qs_sms_short_url: false,

    welcome_mail_subject: 'Benvenuto in CWM',
    welcome_mail_template_name: null,
    welcome_sms_template: null,

    new_ticket_mail_subject: 'Nuova Richiesta Creata',
    new_ticket_mail_template_name: null,
    new_ticket_sms_template: null,

    disclaimer_show: true,
    disclaimer_text: 'Termini e condizioni',
    disclaimer_url: null,

    enable_closing_modal: true,
    enable_chat: true,
    enable_attachments: true,
    enable_gallery: true,
    enable_live_editor: true,
    enable_recording: false,

    fe_public_url: null,
    obfuscate_customer_address_on_close: false,
    firebase_service_account: {},

    ot_api_key: null,
    ot_api_secret: null,
  },
  active: true,
}
const modelName = 'progetto';
const apiResourceName = 'admin/projects'
const filters = {};
const getUpdateUrl = state => `${apiResourceName}/${state.current.key}`;
const beforeUpdate = state => omit(state.current, ['created_at', 'updated_at', 'deleted_at'])
const sortDropdownBy = ['name'];
const state = {
  activeProject: null
}

const getters = {
  getActiveProject: (state) => {
    return state.list.find((project) => project.value === state.activeProject)
  },
  getCapabilities: (state, getters) => {
    if (!getters.getActiveProject) return {}
    return getters.getActiveProject.capabilities
  },
  getSettings: (state, getters) => {
    if (!getters.getActiveProject) return {}
    return getters.getActiveProject && getters.getActiveProject.settings
  },
}


const mutations = {
  SET_ACTIVE(state, project) {
    state.activeProject = project
  },
}

const actions = {
  async preload({ state, dispatch }) {
    // Pass undef so default params can trigger
    await dispatch('adminProjects/getDropdownList', undefined, { root: true })
  },
}



export default storeFactory({
  modelName,
  apiResourceName,
  filters,
  model,
  state,
  mutations,
  getters,
  actions,
  getUpdateUrl,
  beforeUpdate,
  sortDropdownBy,
});

