<script>
export default {
  components: {},
}
</script>

<template>
  <v-app>
    <v-img src="@assets/images/background_login.jpg" class="bg"></v-img>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<style scoped>
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
