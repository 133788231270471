import Fingerprint2 from 'fingerprintjs2'

export const getBrowserFingeprint = async () => {
  const components = await Fingerprint2.getPromise()

  return Fingerprint2.x64hash128(components.map((c) => c.value).join(''), 31)
}

export const debounce = (func, wait, immediate) => {
  let timeout
  return function() {
    const context = this
    const args = arguments
    const later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}
