import { objectToFormData } from '@utils/formdata.js'
import storeFactory from '@state/template'

const apiResourceName = 'customers/attachments'
const modelName = 'allegato'
const model = {
  created_at: null,
  customer_id: null,
  deleted_at: null,
  description: null,
  extra: {},
  file: null,
  filename: null,
  id: null,
  name: null,
  revision: null,
  size: null,
  ticket_id: null,
  type: null,
  updated_at: null,
  uploaded_by: null,
  uri: null,
  visible: false,
}
const filters = {
  customer_id: null,
  ticket_id: null,
  name: null,
  filename: null,
  type: null,
}
const getCreateUrl = () => {
  return `${apiResourceName}/upload`
}
const beforeCreate = (state) => {
  /* eslint-disable camelcase */
  const {
    customer_id,
    description,
    file,
    name,
    ticket_id,
    visible,
  } = state.current
  const final = {
    customer_id,
    description,
    file,
    name,
    visible,
  }
  if (ticket_id) final.ticket_id = ticket_id
  return objectToFormData(final)
}

const dropdownScopes = ['dropdown']
const sortDropdownBy = ['name']
const sortDropdownDesc = [false]

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  getCreateUrl,
  beforeCreate,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
