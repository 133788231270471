<template>
  <div id="app">
    <RouterView />
  </div>
</template>

<script>
import appConfig from '@src/app.config'

export default {
  components: {},
  page: {
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
}
</script>

<style lang="scss">
@import '~nprogress/nprogress.css';
</style>
