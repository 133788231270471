import { netRequest } from '@api/client'
import * as Filesaver from 'file-saver'

export default ({
  apiResourceName,
  modelName,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
  getSearchUrl,
  getCurrentUrl,
  getCreateUrl,
  beforeCreate,
  getCreateMessage,
  getCreateMessageError,
  getUpdateUrl,
  beforeUpdate,
  getUpdateMessage,
  getUpdateMessageError,
  getDeleteUrl,
  getDeleteMessage,
  getDeleteMessageError,
  getBulkUrl,
  getBulkMessage,
  getBulkMessageError,
  getDropdownUrl,
  getDownloadUrl,
  getDownloadFileName,
  getDownloadMessage,
  getDownloadMessageError,
  parseFilters,
  parseError,
}) => ({
  /**
   * Retrieves a page of results, used by tables.
   * @param VuexActionContext
   * @param PaginationCtx
   * @returns {Promise<void>}
   */
  async getItems({ commit, state }, ctx) {
    try {
      commit('SET_LOADING')

      const filters = parseFilters(state.filters)
      const { scopes } = state
      const data = await netRequest('POST', getSearchUrl(state), {
        filters,
        scopes,
        ctx,
      })

      commit('SET_PAGINATED_ITEMS', data)
    } catch (err) {
      /* TO DO */
      /* Should implement error handling */
    } finally {
      commit('RESET_LOADING')
    }
  },

  /**
   * Get the given resource by PK
   * @param VuexActionContext
   * @param {string} id
   * @returns {Promise<void>}
   */
  async setCurrent({ commit, state }, id) {
    try {
      commit('SET_LOADING')
      const data = await netRequest('GET', getCurrentUrl(id || state))
      commit('SET_CURRENT', data)
    } catch (err) {
      /* TO DO: ADD ERROR HANDLING */
    } finally {
      commit('RESET_LOADING')
    }
  },

  /**
   * Create a new resource using the state.current.
   * @param VuexActionContext
   * @returns {Promise<any>}
   */
  async create({ commit, state, dispatch }) {
    let data
    try {
      commit('SET_LOADING')
      data = await netRequest('POST', getCreateUrl(state), beforeCreate(state))
      dispatch('notifications/info', getCreateMessage(state), { root: true })
    } catch (err) {
      dispatch('notifications/error', getCreateMessageError(parseError(err)), {
        root: true,
      })
      console.warn('create error', err)
      throw err
    } finally {
      commit('RESET_LOADING')
    }
    return data
  },

  /**
   * Update a resource using the state.current.
   * @param VuexActionContext
   * @returns {Promise<any>}
   */
  async update({ commit, state, dispatch }) {
    let data
    try {
      commit('SET_LOADING')
      data = await netRequest('PUT', getUpdateUrl(state), beforeUpdate(state))
      commit('SET_CURRENT', data)
      dispatch('notifications/info', getUpdateMessage(state), { root: true })
    } catch (err) {
      dispatch('notifications/error', getUpdateMessageError(parseError(err)), {
        root: true,
      })
      console.warn('create error', err)
    } finally {
      commit('RESET_LOADING')
    }
    return data
  },

  /**
   * Creates many resources from file
   * @param VuexActionContext
   * @returns {Promise<void>}
   */
  async createBulk({ commit, state, dispatch }) {
    try {
      commit('SET_LOADING')

      if (state.current.file) {
        const data = new FormData()
        data.append('file', state.current.file)
        const res = await netRequest('POST', getBulkUrl(state), data)

        dispatch('notifications/info', getBulkMessage(res), { root: true })
      } else {
        console.error(`Nessun file in ${apiResourceName}.state.current.file`)
      }
    } catch (err) {
      dispatch('notifications/error', getBulkMessageError(parseError(err)), {
        root: true,
      })
      throw err
    } finally {
      commit('RESET_LOADING')
    }
  },

  /**
   * Deletes the given resource by PK
   * @param VuexActionContext
   * @param {string} id
   * @returns {Promise<void>}
   */
  async removeItem({ commit, state, dispatch }, id) {
    try {
      commit('SET_LOADING')
      /** Pass an arbitrary useful value
       *  or use the state for figuring it out
       */
      await netRequest('DELETE', getDeleteUrl(id || state))

      dispatch('notifications/info', getDeleteMessage(state), { root: true })
    } catch (err) {
      dispatch('notifications/error', getDeleteMessageError(parseError(err)), {
        root: true,
      })
      console.warn('Delete error', err)
    } finally {
      commit('RESET_LOADING')
    }
  },

  /**
   *
   * @param {any} VuexActionContext
   * @param {any} SearchConfig
   */
  async getDropdownList(
    { commit, state },
    { filters = state.filters, scopes = dropdownScopes, sortBy = sortDropdownBy, sortDesc = sortDropdownDesc} = {}
  ) {
    let data
    try {
      commit('SET_LOADING')

      data = await netRequest('POST', getDropdownUrl(state), {
        filters: parseFilters(filters),
        scopes,
        ctx: {
          page: 1,
          itemsPerPage: -1,
          sortBy,
          sortDesc,
        },
      })

      commit('SET_LIST', data.rows)
    } catch (err) {
    } finally {
      commit('RESET_LOADING')
    }
    return data
  },

  async download({ commit, state, dispatch }, { format, ctx }) {
    try {
      commit('SET_LOADING')

      dispatch('notifications/info', getDownloadMessage(state), { root: true })

      const buffer = await netRequest(
        'POST',
        getDownloadUrl(state),
        {
          filters: parseFilters(state.filters),
          scopes: ['export'],
          ctx,
          format,
        },
        { responseType: 'arraybuffer' }
      )

      const blob = new Blob([buffer])
      await Filesaver.saveAs(blob, getDownloadFileName({ format, state }))
    } catch (error) {
      dispatch(
        'notifications/error',
        getDownloadMessageError(parseError(error)),
        { root: true }
      )
    } finally {
      commit('RESET_LOADING')
    }
  },
})
