import { lazyLoadView } from '@router/routes.js'
import store from '@state/store'
import { setApiAuthToken, netRequest } from '@api/client.js'
import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules.js'
import { getBrowserFingeprint } from '@utils/browser'

export default [
  {
    path: '/',
    component: () => lazyLoadView(import('@views/public.vue')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        await store.dispatch('sessions/getEnvData')
        await store.dispatch('auth/getCsrfToken')
        return next()
      },
    },
    children: [
      {
        path: '/',
        name: 'public_home',
        redirect: { name: 'login' },
        // component: () => lazyLoadView(import('@views/public/home.vue')),
        // meta: {
        //   authRequired: false,
        // },
      },
      {
        path: '/v/done',
        name: 'session_done',
        component: () => lazyLoadView(import('@views/public/done.vue')),
        meta: {
          authRequired: false,
        },
      },
      {
        path: '/v/suspended',
        name: 'session_suspended',
        component: () => lazyLoadView(import('@views/public/suspended.vue')),
        meta: {
          authRequired: false,
        },
      },
      {
        path: '/v/expired',
        name: 'session_expired',
        component: () => lazyLoadView(import('@views/public/expired.vue')),
        meta: {
          authRequired: false,
        },
      },
      {
        path: '/v/declined',
        name: 'session_declined',
        component: () => lazyLoadView(import('@views/public/declined.vue')),
        meta: {
          authRequired: false,
        },
      },

      {
        path: '/agent/:session_id',
        name: 'public_agent_video',
        component: () => lazyLoadView(import('@views/public/agent.vue')),
        props: true,
        meta: {
          authRequired: false,
          async beforeResolve(routeTo, routeFrom, next) {
            setApiAuthToken(routeTo.query.t)
            dispatchActionForAllModules('preload')

            await store.dispatch(
              'sessions/setCurrent',
              routeTo.params.session_id
            )

            if (store.getters['sessions/getItem']) {
              return next()
            }

            next({
              name: 'session_expired',
            })
          },
        },
      },
    ],
  },
  {
    path: '/v',
    component: () => lazyLoadView(import('@views/full_page_public.vue')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        await store.dispatch('auth/getCsrfToken')
        return next()
      },
    },
    children: [
      {
        path: '/v/:project/:sid',
        name: 'public_video',
        component: () => lazyLoadView(import('@views/public/video.vue')),
        props: true,
        meta: {
          authRequired: false,
        },
      },
    ],
  },
  {
    path: '/t',
    component: () => lazyLoadView(import('@views/full_page_public.vue')),
    children: [
      {
        path: '/t/:token',
        name: 'public_tickets_detail',
        component: () => import('@views/tickets/details.vue'),
        meta: {
          authRequired: false,
          async beforeResolve(routeTo, routeFrom, next) {
            try {
              const fingerprint = await getBrowserFingeprint()
              const response = await netRequest('POST', 'ticket_external', {
                encodedToken: routeTo.params.token,
                fingerprint: fingerprint,
              })
              setApiAuthToken(response.auth_token)
              await store.dispatch('auth/setCurrent', {
                user: { id: response.user_id },
              })
              await store.dispatch('auth/validate')
              await store.dispatch('auth/init')
              routeTo.params.id = response.ticket_id
              routeTo.params.magic_link = true
              return next()
            } catch (err) {
              return next({
                name: '403',
              })
            }
          },
        },
      },
    ],
  },
  {
    path: '/virtualagent/',
    component: () =>
      lazyLoadView(import('@views/public/virtualagent/base.vue')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        await store.dispatch('auth/getCsrfToken')
        return next()
      },
    },
    children: [
      {
        path: '/virtualagent/:sid',
        name: 'public_virtualagent',
        component: () =>
          lazyLoadView(import('@views/public/virtualagent/session.vue')),
        props: true,
        meta: {
          authRequired: false,
        },
      },
    ],
  },
]
