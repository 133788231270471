import storeFactory from '@state/template'
import { objectToFormData } from '@utils/formdata.js'

const apiResourceName = 'virtualagents/attachments'
const modelName = 'virtualagentsattachments'

const model = {
  id: null,
  name: null,
  filename: null,
  file: null,
  description: null,
  type: null,
  uri: null,
  size: null,
  created_at: null,
  updated_at: null,
  attachments: [],
}

const filters = {
  name: null,
  description: null,
  filename: null,
}

const getCreateUrl = () => {
  return `${apiResourceName}/upload`
}

const beforeCreate = (state) => {
  /* eslint-disable camelcase */
  const {
    description,
    file,
    name,
  } = state.current
  const final = {
    description,
    file,
    name,
  }
  return objectToFormData(final)
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  getCreateUrl,
  beforeCreate,
  filters

})
