import storeFactory from '@state/template'

const apiResourceName = 'customers/users_groups'
const modelName = 'gruppo'
const model = {
  created_at: null,
  customer_id: null,
  customers_location_id: null,
  deleted_at: null,
  enabled: null,
  id: null,
  name: null,
  updated_at: null,
}

const filters = {
  customer_id: null,
  enabled: null,
  name: null,
}

const sortDropdownBy = ['name']

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  sortDropdownBy,
})
