import storeFactory from '@state/template'

const apiResourceName = 'plans/subscriptions'
const modelName = 'sottoscrizione'

const model = {
  channel: null,
  code: null,
  customer_id: null,
  date_expiration: null,
  date_renewal: null,
  date_start: null,
  max_devices: -1,
  max_devices_per_user: null,
  max_ticket_per_cycle: null,
  max_ticket_per_duration: null,
  max_ticket_per_month: null,
  max_ticket_per_user: null,
  max_users: -1,
  options: {
    enrollment: {
      devices_brand: null,
      devices_model: null,
      devices_num: 0,
      enabled: false,
      name: 'Enrollment',
    },
    furto: {
      devices_brand: null,
      devices_model: null,
      devices_num: 0,
      enabled: false,
      name: 'Furto',
      partner: null,
      pool_coverage: 0,
    },
    help_desk_app: {
      app: null,
      devices_num: 0,
      enabled: false,
      name: 'Help Desk altre APP',
    },
    help_desk_classico: {
      channels: null,
      devices_num: 0,
      enabled: false,
      name: 'Help Desk Classico',
    },
    help_desk_kasko: {
      channels: null,
      devices_num: 0,
      enabled: false,
      name: 'Help Desk solo Kasko',
    },
    help_desk_mdm: {
      channels: null,
      devices_num: 0,
      enabled: false,
      mdm: null,
      name: 'Help Desk MDM',
    },
    kasko: {
      devices_brand: null,
      devices_model: null,
      devices_num: 0,
      enabled: false,
      name: 'Kasko',
      partner: null,
      pool_coverage: 0,
    },
    kitting: {
      devices_brand: null,
      devices_model: null,
      devices_num: 0,
      enabled: false,
      name: 'Kitting',
    },
    notes: null,
    pin: null,
    scorta_calda: {
      devices_brand: null,
      devices_model: null,
      devices_num: 0,
      enabled: false,
      name: 'Scorta Calda',
    },
    time_range: [8, 19],
    weekdays: [0, 1, 2, 3, 4, 5],
  },
  payment_method: null,
  plan_id: null,
  status: null,
  customer_user_reference_id: null, 
}

const filters = {
  channel: [],
  code: null,
  customer_id: [],
  date_expiration: null,
  date_start: null,
  plan_id: [],
  project_key: [],
  status: [],
}

const getters = {
  statusMap: () => ({
    active: 'Attiva',
    waiting: 'In attesa di validazione',
    expired: 'Scaduta',
    cancelled: 'Cancellata',
  }),
}

const sortDropdownBy = ['status']
const sortDropdownDesc = [false]

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  getters,
  filters,
  sortDropdownBy,
  sortDropdownDesc,
})
