import storeFactory from '@state/template'

const apiResourceName = 'nav_entries'
const modelName = 'Link'

const model = {
  text: null,
  href: null,
  target: '_blank',
  image: null,
  customer_id: null,
  customer_location_id: null,
}

const state = {
  targets: [
    { text: 'Apri in una nuova scheda', value: '_blank' },
    { text: 'Apri nella stessa scheda', value: '_top' },
  ]
}

const actions = {
  preload({ dispatch }) {
    return dispatch('getDropdownList')
  }
}


const dropdownScopes = []
const sortDropdownBy = ['text']
const sortDropdownDesc = [false]

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  state,
  actions,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})

