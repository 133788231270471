import storeFactory from '@state/template'

const apiResourceName = 'products'
const modelName = 'prodotto'

const model = {
  active: false,
  brand: null,
  color: null,
  cost_additional: 0.0,
  cost_hardware: 0.0,
  cost_insurance: 0.0,
  cost_maintenance: 0.0,
  cost_management: 0.0,
  cost_setup_additional: 0.0,
  cost_setup_base: 0.0,
  cost_shipping: 0.0,
  cost_software: 0.0,
  description: null,
  external_id: null,
  extra: {},
  model: null,
  part_number: null,
  storage: null,
  type: null,
  variant: null,
}

const filters = {
  brand: null,
  model: null,
  type: null,
  part_number: null,
  external_id: null,
  active: true,
  variant: null,
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
})
