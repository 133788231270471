import Vue from 'vue'
import { LMap, LMarker, LPopup, LTileLayer } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { Icon as Licon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import '@styles/leaflet.css'

Vue.component('LMap', LMap)
Vue.component('LTileLayer', LTileLayer)
Vue.component('LMarker', LMarker)
Vue.component('LPopup', LPopup)
Vue.component('VMarkerCluster', Vue2LeafletMarkerCluster)

delete Licon.Default.prototype._getIconUrl

Licon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
