import moment from 'moment'
import storeFactory from '@state/template'
import { netRequest } from '@api/client.js'

const apiResourceName = 'tickets'
const modelName = 'ticket'

const model = {
  activity: {
    category: null,
    macro: null,
    subcategory: null,
  },
  activity_id: null,
  asset_id: null,
  channel: null,
  customer_id: null,
  customer_location_id: null,
  customer_notes: null,
  customer_user_id: null,
  customer_callback_num: null,
  slot_id: null,
  date_appointment: null,
  date_appointment_end: null,
  external_id: null,
  file: null,
  files: [],
  operator_notes: null,
  partner_ticket_id: null,
  partner_ticket_tracking: null,
  plans_subscription_id: null,
  received_at: null,
  resolution: 'Nuova',
  status: 'ACQUISITA',
  solicited_at: null,
  solicited_managed_at: null,
  prioritary: false,
  additional_fields: [],
}

const filters = {
  anomaly: null,
  assigned_at: null,
  category: [],
  channel: [],
  closed_at: null,
  created_at: null,
  customer_id: null,
  customer_user_id: null,
  date_appointment: null,
  external_id: null,
  kasko: null,
  macro: [],
  managed_at: null,
  managed_by: null,
  resolution: [],
  serial_imei: null,
  solicited_at: null,
  status: [],
  subcategory: [],
  tobe_closed_by: null,
  tobe_managed_by: null,
  type: [],
  additional_fields: [],
}

const state = {
  anomalies: [
    { value: 'anomaly_no_appointment', text: 'Lavorazioni senza appuntamento' },
    {
      value: 'anomaly_no_manage',
      text: 'Lavorazioni non ancora presa in carico',
    },
    { value: 'anomaly_sla_1', text: 'SLA1: Presa in carico' },
    { value: 'anomaly_sla_2', text: 'SLA2: Chiusura lavorazione' },
    { value: 'anomaly_solicit_not_taken', text: 'Sollecito ricevuto' },
    { value: 'anomaly_solicit_taken', text: 'Sollecito gestito' },
  ],
  todayPending: [],
}

const dropdownScopes = []
const sortDropdownBy = []
const sortDropdownDesc = []

const getters = {
  // get if ticket is assigned to current user
  isAssignedToUser(state, getters, rootState, rootGetters) {
    return state.current.managed_by === rootGetters['auth/getCurrentUsername']
  },

  // get if ticket is assigned to current user
  canSolicitTicket(state, getters, rootState, rootGetters) {
    return (
      !state.current.closed_at &&
      (!state.current.prioritary ||
        (state.current.prioritary && state.current.solicited_managed_at))
    )
  },

  isPendingSolicit(state, getters, rootState, rootGetters) {
    return (
      state.current.prioritary &&
      state.current.solicited_at &&
      !state.current.solicited_managed_at
    )
  },

  getFormattedDateAppointment(state) {
    if (!state.current.date_appointment) return null
    return moment(state.current.date_appointment).format('DD/MM/YYYY HH:mm')
  },
  getFormattedDateAppointmentEnd(state) {
    if (!state.current.date_appointment_end) return null
    return moment(state.current.date_appointment_end).format('DD/MM/YYYY HH:mm')
  },
}

const mutations = {
  SET_PENDING(state, value) {
    console.info('set pending', value)
    state.todayPending = value
  },
  RESET_PENDING(state) {
    state.todayPending = []
  },
}

const actions = {
  async getCurrentRelatedData({ commit, state, rootState, dispatch }) {
    try {
      commit('SET_LOADING')

      const reqs = [
        // dettagli cliente
        dispatch('customers/setCurrent', state.current.customer_id, {
          root: true,
        }),

        // ticket activity
        dispatch('ticketsActivities/setCurrent', state.current.activity_id, {
          root: true,
        }),

        // dettagli utente
        ...(state.current.customer_user_id
          ? [
              dispatch(
                'customersUsers/setCurrent',
                state.current.customer_user_id,
                {
                  root: true,
                }
              ),
            ]
          : []),

        // aggiungo location se presente
        ...(state.current.customer_location_id
          ? [
              dispatch(
                'customersLocations/setCurrent',
                state.current.customer_location_id,
                {
                  root: true,
                }
              ),
            ]
          : []),

        // aggiungo location se presente
        ...(state.current.plans_subscription_id
          ? [
              dispatch(
                'plansSubscriptions/setCurrent',
                state.current.plans_subscription_id,
                {
                  root: true,
                }
              ),
            ]
          : []),

        // aggiungo asset
        ...(state.current.asset_id
          ? [
              dispatch('customersAssets/setCurrent', state.current.asset_id, {
                root: true,
              }),
            ]
          : []),
      ]

      await Promise.all(reqs)
    } catch (err) {
    } finally {
      commit('RESET_LOADING')
    }
  },

  async getPendingTickets({ commit, state, rootState, dispatch }) {
    try {
      const today = new Date().toISOString().split('T')[0]
      const data = await netRequest('POST', `tickets/search`, {
        ctx: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['date_appointment'],
          sortDesc: [false],
        },
        filters: { date_appointment: [today, today] },
        scopes: ['customer', 'activity'],
      })

      commit('SET_PENDING', data.rows)
    } catch (err) {
      commit('SET_PENDING', [])
    }
  },
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  mutations,
  filters,
  state,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
  getters,
  actions,
})
