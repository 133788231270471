import storeFactory from '@state/template'

const apiResourceName = 'plans/otp'
const modelName = 'OTP'

const model = {
  id: null,
  sender: null,
  recipient: null,
  customer_id: null,
  plans_subscription_id: null,
  otp: null,
}

const filters = {
  brand: null,
  model: null,
  type: null,
  part_number: null,
  external_id: null,
  active: true,
  variant: null,
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
})
