import storeFactory from '@state/template'
// import _ from 'lodash'

const apiResourceName = 'agendas'
const modelName = 'agenda'
const model = {
  name: null,
  duration: 30,
  time_start: '08:00',
  time_end: '18:00',
  weekdays: [1, 2, 3, 4, 5],
  excluded: [],
  active: true,
  macros: [],
  categories: [],
  customer_ids: [],
  customers_location_ids: [],
  distribution_list: [],
  slots_delay: 3,
}
const filters = {
  name: null,
  active: null,
  strict: false,
  macros: null,
  categories: null,
  customer_ids: [],
  customers_location_ids: [],
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
})
