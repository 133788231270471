import storeFactory from '@state/template'

const apiResourceName = 'customers/appointments'
const modelName = 'appuntamento'
const model = {
  agent_notes: null,
  assigned_to: null,
  closed_at: null,
  confirmed_at: null,
  created_at: null,
  created_by: null,
  customer_id: null,
  customer_location_id: null,
  date_appointment: null,
  date_appointment_end: null,
  date_appointment_expiration: null,
  deleted_at: null,
  id: null,
  lock_agenda: false,
  managed_at: null,
  operator_notes: null,
  rescheduled_at: null,
  resolution: null,
  slot_id: null,
  status: null,
  system_id: null,
  ticket_id: null,
  updated_at: null,
}

const filters = {
  date_appointment: null,
  date_appointment_end: null,
  status: null,
  resolution: null,
  customer_id: null,
  ticket_id: null,
  slot_id: null,
}

const dropdownScopes = ['dropdown']
const sortDropdownBy = ['date_appointment']
const sortDropdownDesc = [true]

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
