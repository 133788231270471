import { isEmpty, isString, isArray, overEvery } from 'lodash'
import storeFactory from '@state/template'

const apiResourceName = 'tickets/activities'
const modelName = 'attività'

const model = {
  active: true,
  avg_time: 30,
  category: null,
  customer_id: null,
  extra: {},
  id: null,
  macro: null,
  priority: 5,
  project_id: null,
  excluded_roles: [],
  sla_1: 180,
  sla_2: 1440,
  sla_3: null,
  subcategory: null,
  tw_adjustment: 30,
  tw_adjustment_mode: 'none',
  type: 'remote',
  description: null,
  appointment_required: false,
  attachment_required: false,
  grouping_key: null,
  custom_type: null,
  additional_fields: [],
  distribution_list: [],
  platform_user_id: null,
  magic_link: false,
  asset_required: null,
  external_id_required: null,
  template: null,
}
const filters = {
  active: null,
  category: null,
  customer_id: null,
  macro: null,
  project_id: null,
  type: null,
}
const state = {
  types: [
    { value: 'remote', text: 'Intervento Remoto' },
    { value: 'onfield', text: 'Attività On-Field' },
    { value: 'phone', text: 'Intervento Telefonico' },
    { value: 'mail', text: 'Supporto Via Mail' },
    { value: 'other', text: 'Altro' },
  ],
}

const By = {
  customerId: (customerId) => (activity) => {
    let pass = true
    // Perform checks only for activities that are linked
    if (activity.customer_id) {
      if (isString(customerId)) pass = customerId === activity.customer_id
      else if (isArray(customerId) && !isEmpty(customerId))
        pass = customerId.includes(activity.customer_id)
    }
    return pass
  },
  macro: (macro) => (activity) => {
    let pass = true
    if (isString(macro)) pass = macro === activity.macro
    else if (isArray(macro) && !isEmpty(macro))
      pass = macro.includes(activity.macro)
    return pass
  },
  category: (category) => (activity) => {
    let pass = true
    if (isString(category)) pass = category === activity.category
    else if (isArray(category) && !isEmpty(category))
      pass = category.includes(activity.category)
    return pass
  },
  subcategory: (subcategory) => (activity) => {
    let pass = true
    if (isString(subcategory)) pass = subcategory === activity.subcategory
    else if (isArray(subcategory) && !isEmpty(subcategory))
      pass = subcategory.includes(activity.subcategory)
    return pass
  },
}

const getters = {
  /**
   * Passed an obj that hold props as filters it will return activities that comply with the filters.
   * Every prop must be either string or array to be used, otherwise it will be ignored.
   */
  activitiesByContext: (state) => (context = {}) => {
    // If those are undef checks will pass straight away
    const { customerId, macro, category, subcategory } = context
    return state.list.filter(
      overEvery([
        By.customerId(customerId),
        By.macro(macro),
        By.category(category),
        By.subcategory(subcategory),
      ])
    )
  },
  macrosByContext: (state, getters) => (context) => {
    const filtered = getters.activitiesByContext(context)
    return [...new Set(filtered.map((a) => a.macro))]
  },
  categoriesByContext: (state, getters) => (context) => {
    const filtered = getters.activitiesByContext(context)
    return [...new Set(filtered.map((a) => a.category))]
  },
  subcategoriesByContext: (state, getters) => (context) => {
    const filtered = getters.activitiesByContext(context)
    return [...new Set(filtered.map((a) => a.subcategory))]
  },
  activityByTriplet: (state) => (macro, category, subcategory) => {
    return state.list.find(
      (a) =>
        a.macro === macro &&
        a.category === category &&
        a.subcategory === subcategory
    )
  },
}

const actions = {
  async preload({ dispatch }) {
    return dispatch('getDropdownList')
  },
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  state,
  getters,
  actions,
})
