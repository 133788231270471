import storeFactory from '@state/template'

const apiResourceName = 'admin/users_devices'
const modelName = 'dispositivo utente'

const model = {
  app_name: null,
  app_version: null,
  created_at: null,
  device_manufacturer: null,
  device_model: true,
  fingerprint: null,
  id: null,
  last_access_ip: null,
  last_access_time: null,
  os_name: null,
  os_version: null,
  push_notification_id: null,
  type: null,
  updated_at: null,
  user_agent: null,
  user_id: null,
}
const filters = {
  user_id: null,
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
})
