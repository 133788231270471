import Vue from 'vue'
import Vuex from 'vuex'
import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules'

import pathify from './pathify'
import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  plugins: [pathify.plugin],
  strict: process.env.NODE_ENV !== 'production',
})

export default store

dispatchActionForAllModules('init')
