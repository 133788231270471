export const state = {
  drawer: false,
  footer: true,
  section: null,
  dark_mode:
    (window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches) ||
    false,
}

export const getters = {}

export const mutations = {
  SET_DRAWER(state, value) {
    state.drawer = value
  },
  SET_FOOTER(state, value) {
    state.footer = value
  },
  TOGGLE_DRAWER(state) {
    state.drawer = !state.drawer
  },
  SET_DARK_MODE(state, value) {
    state.dark_mode = value
  },
  SET_SECTION(state, value) {
    state.section = value
  },
}

export const actions = {
  preload({ state, dispatch }) {},
}
