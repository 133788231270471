import { netRequest } from '@/src/api/client'
import storeFactory from '@state/template'

const model = {
  availability: null,
  color: null,
  end: { end: null },
  id: null,
  locked: null,
  name: null,
  start: { start: null },
  used: null,
}

const modelName = 'slot'
const apiResourceName = 'agendas/slots'
const filters = {
  start: null,
  end: null,
  agenda_id: null,
  show_all: false,
}

const getCreateUrl = () => `${apiResourceName}/generate`
const beforeUpdate = (state) => ({
  locked: state.current.locked,
  availability: state.current.availability,
})

const dropdownScopes = ['events']
const sortDropdownBy = []
const sortDropdownDesc = []

const isSelected = (slot) => !!slot.selected
const isLocked = (slot) => !!slot.locked
const usedRatio = (slot) => slot.used / slot.availability
const usedMoreThan = (n) => (slot) => n < usedRatio(slot)

const getSlotColor = (s) => {
  if (isSelected(s)) return '#ece203' // Greeny Yellow

  if (isLocked(s) || s.used === s.availability) return '#7c7c7c' // 50% grey

  if (usedMoreThan(0.7)(s)) return '#cb2a2a' // Dark red

  if (usedMoreThan(0.4)(s)) return '#f5741b' // Orange

  return '#06a008' // Green
}

const getEventName = (slot) => {
  let name = `${slot.availability - slot.used} Disponibili`
  if (isLocked(slot)) name = 'Bloccato'
  return name
}

const getters = {
  // This maps the slots as events
  getEvents(state) {
    return state.list.map((slot) => ({
      name: getEventName(slot),
      color: getSlotColor(slot),
      ...slot,
    }))
  },
}

const mutations = {
  HIGHLIGHTS_CURRENT_LIST(state, id) {
    const selected = state.list.find(isSelected)
    const isAlreadySelected = selected?.id === id
    if (isAlreadySelected) return

    state.list = state.list.map((slot) => {
      // Deselect currently selected
      if (isSelected(slot)) return { ...slot, selected: false }
      // If it's the one to select add the prop
      if (slot.id === id) return { ...slot, selected: true }
      // Otherwise do nothing
      return slot
    })
  },
}

const actions = {
  /**
   * Overrides the default create as it takes a range of dates to create a group of slots.
   * @param VuexActionContext
   * @returns {Promise<any>}
   */
  async create({ commit, state, dispatch }, opts) {
    let data
    try {
      commit('SET_LOADING')
      data = await netRequest('POST', getCreateUrl(state), opts)
      dispatch(
        'notifications/info',
        `Slots: Operazione terminata con successo.`,
        { root: true }
      )
    } catch (err) {
      dispatch(
        'notifications/error',
        `Errore durante la creazione delle slots..`,
        { root: true }
      )
      throw err
    } finally {
      commit('RESET_LOADING')
    }
    return data
  },
}

export default storeFactory({
  model,
  modelName,
  apiResourceName,
  filters,
  getters,
  mutations,
  actions,
  getCreateUrl,
  beforeUpdate,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
