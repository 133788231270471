import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import it from 'vuetify/es5/locale/it'
import '@mdi/font/css/materialdesignicons.css'
// import colors from 'vuetify/lib/util/colors.js'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    icons: {
      iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    themes: {
      light: {
        primary: '#004691',
        secondary: '#EB0028',
        accent: '#e71132',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        bg_toolbar: '#ffffff',
        bg_section: '#ffffff',
        background: '#f2f5f8',
        btn_default: '#d4d4d4',
      },
      dark: {
        primary: '#2470c4',
        secondary: '#ea3051',
        bg_toolbar: '#121212',
        bg_section: '#1e1e1e',
        background: '#131313', // colors.grey.darken4,
        btn_default: '#272727',
      },
    },
  },
  lang: {
    locales: { it },
    current: 'it',
  },
})
