import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import NProgress from 'nprogress/nprogress'
import store from '@state/store'
import { LogEvent } from '@services/firebase.js'
import routes from './routes'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  keyName: 'page',
})

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  LogEvent('page_view', { type: 'internal' })

  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    NProgress.start()
  }

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next()

  // If auth is required and the user is logged in...
  if (store.getters['auth/loggedIn']) {
    // Validate the local user token...
    return store.dispatch('auth/validate').then((validUser) => {
      validUser
        ? validUser.user.is_temp_password &&
          routeTo.name !== 'profile' &&
          routeTo.name !== 'logout'
          ? redirectToPasswordUpdate()
          : checkUserPermission(validUser)
        : redirectToLogin()
    })
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin()

  function redirectToLogin() {
    NProgress.done()

    // Pass the original route to the login component
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }

  function checkUserPermission(user) {
    const { permissions } = user.role
    const { section, action, denyAction } = routeTo.meta
    const actions = [action]
    const denyActions = [denyAction]

    if (section && !permissions[section]) {
      console.warn('Utente non può accedere a ' + section, action)
      return next({ name: '403' })
    }

    const allowedActions = section
      ? Object.keys(permissions[section]).filter(
          (a) => permissions[section][a] === true
        )
      : null

    if (
      actions &&
      allowedActions &&
      (!actions.every((elem) => allowedActions.includes(elem)) ||
      !denyActions.every((elem) => !allowedActions.includes(elem)))
    ) {
      console.warn(
        'Utente non ha i permessi richiesti.',
        allowedActions,
        actions,
        denyActions
      )
      return next({ name: '403' })
    }

    return next()
  }

  function redirectToPasswordUpdate() {
    NProgress.done()
    next({ name: 'profile', query: { redirectFrom: routeTo.fullPath } })
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done()
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
  NProgress.done()
})

export default router
