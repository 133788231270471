import { isEmpty, merge, cloneDeep, pickBy, isNumber, omit, startCase } from 'lodash'
import moment from "moment"
import initialGetters from '@state/getters'
import initialState from '@state/state'
import createMutations from '@state/mutations'
import createActions from '@state/actions'

/**
 * Creates a new store based on the configuration passed.
 * It has to have an API endpoint, a readable name, and a model.
 * Everything else can default to standard.
 */

const storeFactory = ({
  apiResourceName = '',
  modelName = '',
  model = {},
  filters = {},
  state = {},
  getters = {},
  mutations = {},
  actions = {},
  dropdownScopes = ['dropdown'],
  sortDropdownBy = [],
  sortDropdownDesc = [false],

  /** Given an error object returns an error that
   *  can be notified through the notifications module
   */
  parseError = (error) => {
    if (error.errors) return parseError(error.errors[0])

    let final;
    if (error.message || error.name) final = { message: error.message || error.name }
    else final = { message: 'Errore sconosciuto' };

    return final.message
  },
  /** Used to parse filters before submitting them for search */
  parseFilters = (filters) => {
    const parsed = pickBy(filters, (value) => {
      return (
        value !== null &&
        value !== undefined &&
        (!isEmpty(value) || isNumber(value))
      )
    })

    return parsed
  },
  getSearchUrl = () => {
    return `${apiResourceName}/search`
  },

  getCurrentUrl = id => {
    return `${apiResourceName}/${id}`
  },

  /** Prepares the resource for being created, must return a resource */
  beforeCreate = (state) => {
    return omit(state.current, ['id'])
  },
  getCreateUrl = () => {
    return `${apiResourceName}`
  },
  getCreateMessage = () => `${startCase(modelName)}: creazione effettuata con successo`,
  getCreateMessageError = text => `${startCase(modelName)}: ${text}`,

  /** Prepares the resource for being updated, must return a resource */
  beforeUpdate = (state) => {
    return state.current
  },
  getUpdateUrl = (state) => {
    return `${apiResourceName}/${state.current.id}`
  },
  getUpdateMessage = () => `${startCase(modelName)}: modifica effettuata con successo`,
  getUpdateMessageError = getCreateMessageError,


  getDeleteUrl = id => `${apiResourceName}/${id}`,
  getDeleteMessage = () => `${startCase(modelName)} eliminazione effettuata con successo`,
  getDeleteMessageError = getCreateMessageError,


  getBulkUrl = () => `${apiResourceName}/upload`,
  getBulkMessage = response => {
    const { processed, skipped, errored } = response;
    return `Upload completato. Processati: ${processed}. Skippati: ${skipped}. Errori: ${errored}`
  },
  getBulkMessageError = getCreateMessageError,

  getDropdownUrl = getSearchUrl,


  getDownloadUrl = getSearchUrl,
  getDownloadFileName = ({ format }) => `${modelName}_${moment().format('DD-MM-YYYY-HHmm')}.${format}`,
  getDownloadMessage = () => 'A breve verrà scaricato il file richiesto',
  getDownloadMessageError = getCreateMessageError,

} = {}) => {
  /* Perform minimal validation checks */
  ;[
    [() => !apiResourceName, `It has to have an API resource name`],
    [() => !modelName, `It has to have a human friendly model name`],
    [() => isEmpty(model), `Model has no purpose being empty`],
  ].forEach(([test, message]) => {
    if (test()) console.error(`In ${modelName} store factory: ${message}`)
  })

  // Lodash mutates the object so it needs to be merging into an empty one
  const finalGetters = merge({}, initialGetters, getters)
  const finalState = merge({}, initialState, {
    current: cloneDeep(model),
    filters: cloneDeep(filters),
    ...state,
  })

  /*
  These subfactories are used to avoid storing data
  that serves only a functional purpose in the state
  */
  const finalMutations = merge({}, createMutations({
    filters,
    model,
  }), mutations)

  const finalActions = merge({}, createActions({
    apiResourceName,
    modelName,
    dropdownScopes,
    sortDropdownBy,
    sortDropdownDesc,
    getSearchUrl,
    getCurrentUrl,
    getCreateUrl,
    beforeCreate,
    getCreateMessage,
    getCreateMessageError,
    getUpdateUrl,
    beforeUpdate,
    getUpdateMessage,
    getUpdateMessageError,
    getDeleteUrl,
    getDeleteMessage,
    getDeleteMessageError,
    getBulkUrl,
    getBulkMessage,
    getBulkMessageError,
    getDropdownUrl,
    getDownloadUrl,
    getDownloadFileName,
    getDownloadMessage,
    getDownloadMessageError,
    parseFilters,
    parseError
  }), actions)

  return {
    state: finalState,
    mutations: finalMutations,
    getters: finalGetters,
    actions: finalActions,
  }
}

export default storeFactory
