import storeFactory from '@state/template'

const apiResourceName = 'admin/users_groups'
const modelName = 'gruppo'

const model = {
  description: null,
  enabled: null,
  id: null,
  name: null,
  permissions: null,
}

const filters = {
  description: null,
  enabled: null,
  id: null,
  name: null,
}

const dropdownScopes = ['dropdown']
const sortDropdownBy = ['name']
const sortDropdownDesc = [false]

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
