import { set } from 'lodash'

import storeFactory from '@state/template'

const apiResourceName = 'admin/users_roles'
const modelName = 'ruolo'

const model = {
  groups: [],
  id: null,
  key: null,
  label: null,
  need_2fa: false,
  permissions: {},
}
const filters = {
  id: null,
  key: null,
  label: null,
}

const getters = {
  canRole: (state) => ({ section, operation }) => {
    return !!state.current.permissions?.[section.key]?.[operation.key]
  },
}

const mutations = {
  /**
   * This replaces the current rather than mergin because
   * merge would add the permissions that were
   * present before to the new role
   */
  SET_CURRENT(state, role) {
    state.current = role
  },
  SET_PERMISSIONS(state, { section, operation, value }) {
    state.current = set(
      state.current,
      ['permissions', section, operation],
      value
    )
  },
  SET_KEY_FROM_LABEL(state, label) {
    state.current.key = label.toLowerCase().replace(/\s/g, '_')
  },
}

const sortDropdownBy = ['label']

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  getters,
  mutations,
  sortDropdownBy,
})
