import storeFactory from '@state/template'
import { netRequest } from '@api/client.js'
import { curry, get, toUpper, snakeCase, upperFirst, merge } from 'lodash'

const model = {
  customer_id: null,
  common_name: null,
  first_name: null,
  last_name: null,
  email: null,
  mobile_phone_number: null,
  reason: null,
  service: null,
  ticket_id: null,
  preset_id: null,
  type: null,
  params: {},
  callback_done_at: null,
  callback_by: null,
  callback_count: 0,
  callback_resolution: null,
  notes: null,
  preset: null,
}

const modelName = 'Sessione Agente Virtuale'
const apiResourceName = 'virtualagents/sessions'

const filters = {
  created_at: null,
  callback_at: null,
  customer_id: null,
  created_by: null,
  customers_user_id: null,
  reason: null,
  sentiment: null,
  service: null,
  skill_type: null,
  status: null,
  callback_resolution: null,
}

const state = {
  statuses: [],
  tokenInfo: null,
  publicSession: null,
  error: null,
}
const getFrom = curry(get, 2)

const getters = {
  labels: (state) =>
    getFrom({
      statuses: {
        closed: '✔ Chiusa',
        connected: 'Cliente connesso',
        disconnected: 'Cliente disconnesso',
        pending: 'ð In attesa',
        suspended: 'Sospesa',
        callback: '❗️Ricontatto richiesto',
        callback_upsell: '️💶 Ricontatto Commerciale',
        // rejected: '❗️ Escalation',
        expired: 'Scaduta',
      },
    }),
  statuses: (state, getters) => {
    const getLabel = getFrom(getters.labels('statuses'))
    return state.statuses.map((s) => ({
      text: getLabel(s),
      value: s,
    }))
  },
  resolutions: (state) => {
    return [
      'OK - cliente contattato',
      'KO - cliente irreperibile',
      'Richiesta Rimodulazione',
    ]
  },
}
// item_name_in_state - default_value_of_mock
const indexer = [
  [
    'statuses',
    [
      'pending',
      'connected',
      'suspended',
      'closed',
      'callback',
      'callback_upsell',
    ],
  ],
  ['tokenInfo', null],
  ['publicSession', null],
  ['error', null],
]

const makeMutation = ([key]) => {
  return {
    [`SET_${toUpper(snakeCase(key))}`]: (state, value) => (state[key] = value),
    [`RESET_${toUpper(snakeCase(key))}`]: (state) => (state[key] = []),
  }
}

const makeAction = ([key, defaultValue]) => ({
  [`get${upperFirst(key)}`]: async ({ commit }) => {
    try {
      commit('SET_LOADING')
      const data = await fake(defaultValue)
      commit(`SET_${toUpper(snakeCase(key))}`, data)
    } catch (err) {
    } finally {
      commit('RESET_LOADING')
    }
  },
})

const fake = (data) =>
  new Promise((resolve) => setTimeout(() => resolve(data), 20))

const mutations = {
  ...indexer.map(makeMutation).reduce(merge),
}

const actions = {
  ...indexer.map(makeAction).reduce(merge),

  async getSessionToken({ commit }, { sid }) {
    commit('SET_LOADING')
    let data = null
    try {
      data = await netRequest('GET', `../integrations/dialogflow/token/${sid}`)
      commit('SET_TOKEN_INFO', data)
    } catch (err) {
      console.error('Errore sessione: ', err.message)
    } finally {
      commit('RESET_LOADING')
    }
    return data
  },

  async checkSession({ commit }, { sid }) {
    commit('SET_LOADING')
    let data = null
    try {
      data = await netRequest('GET', `../quicksupport/virtualagent/${sid}`)
      commit('SET_PUBLIC_SESSION', data)
    } catch (err) {
      console.error('Errore sessione: ', err.message)
      commit(
        'SET_ERROR',
        err.message || 'Non è stato possibile contattare il server.'
      )

      // this._vm.$dialog.notify.error('Attenzione, si è verificato un errore.', {
      //   position: 'bottom-right',
      //   timeout: 2000,
      // })
    } finally {
      commit('RESET_LOADING')
    }
    return data
  },
}

export default storeFactory({
  model,
  modelName,
  apiResourceName,
  filters,
  state,
  getters,
  mutations,
  actions,
})
