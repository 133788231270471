import { omit } from 'lodash'
import storeFactory from '@state/template'

const apiResourceName = 'customers/assets/history'
const modelName = 'storico'
const model = {
  asset_id: null,
  description: null,
  extra: null,
  id: null,
  logged_at: null,
  user: null,
  value: null
}

const filters = {
  asset_id: null,
  logged_at: null,
  ticket_id: null,
  user: null
};
const beforeCreate = state => omit(state.current, [
  'id',
  'extra',
  'created_at',
  'updated_at',
  'deleted_at',
])

const beforeUpdate = state => omit(state.current, [
  'id',
  'extra',
  'last_access_at',
  'created_at',
  'updated_at',
  'deleted_at',
  'dealer_id',
])
const dropdownScopes = ['dropdown']
const sortDropdownBy = ['name']
const sortDropdownDesc = [false]


export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  beforeCreate,
  beforeUpdate,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc
})
